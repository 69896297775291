<div class="product-type-category-select-container" #productTypeCategoryContainer>
  <mat-form-field class="chh-form-field example-full-width">
    <mat-label>Filter By Category</mat-label>
    <input matInput data-product-type-category-input="productTypeCategoryInput" class="product-type-category-input" placeholder="Filter by name" placeholderLabel="i.e. Decking....." value="" type="text" #productTypeCategoryFilter (keyup)="filterProductTypeCategories(productTypeCategoryFilter.value, $event)" (focus)="productTypeCategoryFilter.select()" (click)="checkProductTypeCategoryListIsOpen(productTypeCategoryFilter.value)">
  </mat-form-field>

  <mat-icon *ngIf="false" matTooltip="Click to add a Product Type Category" class="clickable-icon person-add" (click)="addProductType()">person_add</mat-icon>
  <div class="select-multiple-container"><mat-slide-toggle #multipleProductTypes (click)="checkMultipleProductTypes(multipleProductTypes.checked)">Multiple Categorys</mat-slide-toggle></div>
  <div class="selected-product-type-categorys-container">
    <div class="selected-product-type-categorys" id="selectedProductTypeCategories" *ngIf="selectedProductTypeCategories != null && selectedProductTypeCategories.length > 0">
      <div class="chip-column">
        <mat-chip-listbox #chipGrid aria-label="ProductType selection">
          @for (selectProduct of selectedProductTypeCategories; track selectProduct; let selProdIdx = $index; let lastItem = $last) {
          <mat-chip-option (removed)="removeProductType(selectProduct)">
            {{selectProduct.txtName}}
            <button matChipRemove [attr.aria-label]="'remove ' + selectProduct.txtName">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip-option>
          }
        </mat-chip-listbox>
      </div>
      <div class="see-more-chips">
        <button mat-icon-button aria-label="expand row" (click)="expandSelectedProductTypeCategories($event)">
          <mat-icon class="expand-icons product-type-category-selection-expand-icon material-icons-outlined" *ngIf="productTypeCategorySelectionExpanded == false ">keyboard_arrow_down</mat-icon>
          <mat-icon class="expand-icons product-type-category-selection-expand-icon material-icons-outlined" *ngIf="productTypeCategorySelectionExpanded == true">keyboard_arrow_up</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div data-product-type-category-select="productTypeCategorySelect" class="product-type-category-container" *ngIf="showProductTypeCategoryList" #productTypeCategoryListContainer>
    <div data-product-type-category-select="productTypeCategorySelect" id="productTypeCategorySelect" class="product-type-category-select-list">
      <div class="product-type-category-pin-row">
        <div class="button" data-push-pin="pushPin" id="btnPushPin" (click)="clickPin($event)" [ngClass]="productTypeCategoryListPinned == true ? 'button-pressed' : 'button'" matTooltip="{{toolTipText}}">
          <mat-icon data-push-pin="pushPin" class="pin-icon">push_pin</mat-icon>
        </div>
      </div>
      <div class="product-type-category-heading-row product-type-category-name-heading">Name</div>
      <div *ngIf="false" class="product-type-category-heading-row product-type-category-update-heading"></div>
      @for(productTypeCategory of productTypeCategoriesFiltered; track productTypeCategory; let idx = $index){
      <div data-product-type-category-select="productTypeCategorySelect" tabindex="{{idx}}" id="productTypeCategoryName{{idx}}" (keyup)="nextProductType($event, productTypeCategory)" class="chh-list-item-row chh-list-item-first-column product-type-category-name" [ngClass]="productTypeCategory.guSelectedProductTypeCategoryId != '' ? 'chh-list-item-row chh-list-item-row-selected product-type-category-first-name' : addressHoverId == productTypeCategory.rowguid ? 'chh-list-item-row chh-list-item-row-hover product-type-category-first-name' : 'chh-list-item-row product-type-category-first-name'" (mouseenter)="rowEnter(productTypeCategory.rowguid)" (mouseleave)="rowLeave()" (click)="selectProductType(productTypeCategory, !(productTypeCategory.guSelectedProductTypeCategoryId != ''))"><div data-product-type-category-select="productTypeCategorySelect" class="product-type-category-text">{{productTypeCategory.txtName}}</div></div>
      <div *ngIf="false" data-product-type-category-select="productTypeCategorySelect" id="productTypeCategoryUpdateDiv{{idx}}" class="chh-list-item-row product-type-category-radio product-type-category-update-div" [ngClass]="productTypeCategory.guSelectedProductTypeCategoryId != '' ? 'chh-list-item-row chh-list-item-row-selected' : addressHoverId == productTypeCategory.rowguid ? 'chh-list-item-row chh-list-item-row-hover' : 'chh-list-item-row'" (mouseenter)="rowEnter(productTypeCategory.rowguid)" (mouseleave)="rowLeave()" (click)="selectProductType(productTypeCategory, !(productTypeCategory.guSelectedProductTypeCategoryId != ''))">
        <div data-product-type-category-select="productTypeCategorySelect" id="productTypeCategoryUpdateButtonDiv{{idx}}" class="update-product-type-category-button">
          <button mat-button (click)="updateProductType(productTypeCategory)" matTooltip="Click to edit ProductType">
            <mat-icon class="clickable-icon chh-icon material-icons-outlined">edit_document</mat-icon>
          </button>
        </div>
      </div>

      }
    </div>

  </div>

</div>
