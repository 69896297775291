import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { dtoProductTypeCategoryGrouped } from '../../_shared/business-objects/dto-product-type-category-grouped.bo';

@Component({
  selector: 'app-product-type-category-select',
  templateUrl: './product-type-category-select.component.html',
  styleUrl: './product-type-category-select.component.scss'
})
export class ProductTypeCategorySelectComponent {
  @ViewChild('productTypeCategoryFilter') productTypeCategoryFilter: ElementRef;
  @ViewChild('productTypeCategoryListContainer') productTypeCategoryListContainer: ElementRef;
  @ViewChild('multipleProductTypes') multipleProductTypes: MatSlideToggle;


  @Input() productTypeCategories: dtoProductTypeCategoryGrouped[];
  @Output() selectedProductTypeCategoriesEvent = new EventEmitter<dtoProductTypeCategoryGrouped[]>();

  showSpinner: boolean
  addressHoverId: string;

  selectedProductTypeCategories: dtoProductTypeCategoryGrouped[];
  productTypeCategoriesFiltered: dtoProductTypeCategoryGrouped[];
  productTypeExpanded: dtoProductTypeCategoryGrouped;

  productTypeFilteredItems: HTMLCollectionOf<HTMLDivElement>;

  showProductTypeCategoryList: boolean = false;
  showProductTypeCategoryListTransition: boolean = false;

  productTypeCategorySelectionExpanded: boolean = false;
  productTypeListPinned: boolean = false;

  toolTipText: string = "pin";

  constructor() {

  }

  ngOnInit() {
    this.selectedProductTypeCategories = [];

    document.addEventListener("click", this.openCloseProductTypeList.bind(this));

  }

  openCloseProductTypeList(e) {

    if (this.productTypeListPinned == true) {
      return;
    }

    //console.log("e.target.dataset", e.target.dataset);

    if (e != null && e.target != null && e.target.dataset != null && e.target.dataset.pushPin != null) {
      return;
    }

    if (this.showProductTypeCategoryList == true && e != null && e.target != null && e.target.dataset != null && e.target.dataset.pushPin == null && e.target.dataset.productTypeCategoryInput == null) {
      //console.log("productTypeCategoryInput == null");
      this.showProductTypeCategoryList = false;
      //return;
    }

    //COMMENTED BELOW OUT,
    //ALLOW ONE CLICK IN CELL TO GO THROUGH
    //WANT TO SHOW ALL CATEGORIES ON CLICK
    if (e != null && e.target != null && e.target.dataset != null && e.target.dataset.productTypeCategoryInput != null && this.productTypeCategoryFilter.nativeElement.value != null && this.productTypeCategoryFilter.nativeElement.value.toString() != "") {
      this.showProductTypeCategoryList = true;
      //console.log("this.showCustomerList = true", this.showCustomerList);
      //console.log("customerListContainer", this.customerFilter.nativeElement.value);

      return;
    }

    //console.log("e.target.dataset.productTypeCategoryInput", e.target.dataset.productTypeCategoryInput);
    //console.log("this.showProductTypeCategoryList", this.showProductTypeCategoryList);
    //if (e == null || e.target == null || e.target.dataset == null || e.target.dataset.productTypeSelect == null) {

    //COMMENTING BELOW OUT FOR NOW - 2024-12-08
    //==================================================================================================
    // ALWAYS CLOSE THE SEARCH LIST UNLESS I'M PINNED
    //if (this.productTypeListPinned == false) {
    //  this.showProductTypeCategoryList = false;

    //  let docBody = document.getElementById("rootBody");
    //  docBody.style.overflowY = "scroll";

    //  console.log("this.showProductTypeCategoryList = false", this.showProductTypeCategoryList);
    //  return;
    //}
    //==================================================================================================


    //let domStringMap: DOMStringMap = e.target.dataset;

    //console.log(domStringMap.customerSelect.toString() == "customerSelect");
    //console.log(domStringMap.customerSelect.toString());
  }

  checkProductTypeCategoryListIsOpen(inputValue: string) {

    //console.log("inputValue", inputValue);

    if (inputValue == null || inputValue == "") {
      //this.showProductTypeCategoryList = false;
      //console.log("inputValue empty", inputValue);
      //return;
    }

    //console.log("inputValue", inputValue);

    this.showProductTypeCategoryList = true;

    this.filterProductTypeCategories("", null);
  }


  rowEnter(productTypeId: string) {
    this.addressHoverId = productTypeId;
    //console.log("row enter");
  }

  rowLeave() {
    this.addressHoverId = "";
    //console.log("row leave");
  }

  async selectProductType(productTypeCategory: dtoProductTypeCategoryGrouped, selected: boolean) {

    if (productTypeCategory == null) {
      //this.alertService.openSnackBarDefault("There was a problem selecting the product Type Category - null option", "Close", "center", "bottom", "", 4000);
      return;
    }

    // Remove All ProductType Contact
    if (this.multipleProductTypes != null && this.multipleProductTypes.checked == false) {

      let productTypeSelected: dtoProductTypeCategoryGrouped[] = this.productTypeCategories.filter((a) => { return a.guSelectedProductTypeCategoryId != null && a.guSelectedProductTypeCategoryId != "" });
      for (let i = 0; i <= productTypeSelected.length - 1; i++) {
        productTypeSelected[i].guSelectedProductTypeCategoryId = "";
      }

      for (let i = this.selectedProductTypeCategories.length - 1; i >= 0; i--) {
        this.selectedProductTypeCategories.splice(i, 1);
      }

      for (let i = this.productTypeCategoriesFiltered.length - 1; i >= 0; i--) {
        this.productTypeCategoriesFiltered[i].guSelectedProductTypeCategoryId = "";
      }

    }

    // Remove ProductType Contact
    if (selected == false) {
      for (let i = this.selectedProductTypeCategories.length - 1; i >= 0; i--) {
        if (this.selectedProductTypeCategories[i].rowguid == productTypeCategory.rowguid) {
          this.selectedProductTypeCategories.splice(i, 1);
          productTypeCategory.guSelectedProductTypeCategoryId = "";
          //console.log("unselected productType");
          this.selectedProductTypeCategoriesEvent.emit(this.selectedProductTypeCategories);
          break;
        }
      }
    }

    // Add ProductType Contact
    if (selected == true) {

      productTypeCategory.guSelectedProductTypeCategoryId = "tempvalue";

      this.selectedProductTypeCategories.push(productTypeCategory);
      this.selectedProductTypeCategoriesEvent.emit(this.selectedProductTypeCategories);

      if (this.multipleProductTypes.checked == false && this.productTypeListPinned == false) {
        this.showProductTypeCategoryList = false;
      }

      //console.log("added productType");

    }


  }

  addProductType() {

  }

  nextProductType(e, productTypeCategory: dtoProductTypeCategoryGrouped) {

    //console.log("e ", e);

    if (e.code.toLowerCase() == "escape") {
      let docBody = document.getElementById("rootBody");
      docBody.style.overflowY = "scroll";

      this.productTypeCategoryFilter.nativeElement.focus();
      this.productTypeCategoryFilter.nativeElement.setSelectionRange(this.productTypeCategoryFilter.nativeElement.value.length, this.productTypeCategoryFilter.nativeElement.value.length);

      this.showProductTypeCategoryList = false;
      return;
    }

    if ((e.code.toLowerCase() == "arrowdown" || e.code.toLowerCase() == "arrowup") && this.showProductTypeCategoryList == true) {
      //console.log("e ", e);

      if (this.productTypeFilteredItems != null) {

        let tabIndex: number = e.target.tabIndex;

        let productTypeUpdateDiv = document.getElementById("productTypeUpdateDiv" + tabIndex);

        if (tabIndex < this.productTypeFilteredItems.length - 1 && e.code.toLowerCase() == "arrowdown") {

          let productTypeUpdateDivPlus = document.getElementById("productTypeUpdateDiv" + (tabIndex + 1));

          //console.log("e down ", tabIndex);
          //TODO
          // Set this.productTypeContainer.style overflow-y to hidden here
          let docBody = document.getElementById("rootBody");
          docBody.style.overflowY = "hidden";

          this.productTypeCategoryListContainer.nativeElement.classList.add("productType-container-no-scroll");

          this.productTypeFilteredItems[tabIndex].classList.remove("chh-list-item-row-selected");
          productTypeUpdateDiv.classList.remove("chh-list-item-row-selected");

          this.productTypeFilteredItems[tabIndex + 1].focus();
          this.productTypeFilteredItems[tabIndex + 1].classList.add("chh-list-item-row-selected");
          productTypeUpdateDivPlus.classList.add("chh-list-item-row-selected");

          // Set this.productTypeContainer.style overflow-y to scroll here
          this.productTypeCategoryListContainer.nativeElement.classList.remove("productType-container-no-scroll");

        }

        if (tabIndex >= 0 && e.code.toLowerCase() == "arrowup") {

          if (tabIndex == 0) {
            //console.log("up tabIndex ", tabIndex);

            this.productTypeFilteredItems[tabIndex].classList.remove("chh-list-item-row-selected");
            productTypeUpdateDiv.classList.remove("chh-list-item-row-selected");

            let docBody = document.getElementById("rootBody");
            docBody.style.overflowY = "scroll";

            this.productTypeCategoryFilter.nativeElement.focus();
            this.productTypeCategoryFilter.nativeElement.setSelectionRange(this.productTypeCategoryFilter.nativeElement.value.length, this.productTypeCategoryFilter.nativeElement.value.length);
            return;
          }


          let productTypeUpdateDivMinus = document.getElementById("productTypeUpdateDiv" + (tabIndex - 1));

          //console.log("e up ", tabIndex);

          this.productTypeCategoryListContainer.nativeElement.classList.add("productType-container-no-scroll");

          this.productTypeFilteredItems[tabIndex].classList.remove("chh-list-item-row-selected");
          productTypeUpdateDiv.classList.remove("chh-list-item-row-selected");

          this.productTypeFilteredItems[tabIndex - 1].focus();
          this.productTypeFilteredItems[tabIndex - 1].classList.add("chh-list-item-row-selected");
          productTypeUpdateDivMinus.classList.add("chh-list-item-row-selected");

          this.productTypeCategoryListContainer.nativeElement.classList.remove("productType-container-no-scroll");


        }

      }
      //console.log("nextProductType");

    }

    if (e.code.toLowerCase() == "enter") {
      this.selectProductType(productTypeCategory, !(productTypeCategory.guSelectedProductTypeCategoryId != ''));
    }

  }

  filterProductTypeCategories(productTypeFilter: string, e) {


    if (e != null && e.code.toLowerCase() == "arrowdown" && this.showProductTypeCategoryList == true) {
      //console.log("e ", e);
      let docBody = document.getElementById("rootBody");
      docBody.style.overflowY = "hidden";

      this.productTypeFilteredItems = document.getElementsByClassName("product-type-category-name") as HTMLCollectionOf<HTMLDivElement>;
      if (this.productTypeFilteredItems != null && this.productTypeFilteredItems.length > 0) {


        this.productTypeFilteredItems[0].classList.add("chh-list-item-row-selected");
        let productTypeUpdateDiv = document.getElementById("productTypeUpdateDiv0");

        productTypeUpdateDiv.classList.add("chh-list-item-row-selected");

        this.productTypeFilteredItems[0].focus();
        //console.log("filterProductType TabIndex: ", this.productTypeFilteredItems[0].tabIndex);

      }

      //console.log("productTypeElements", this.productTypeFilteredItems);

    }

    this.getProductTypeCategories(productTypeFilter);



  }

  getProductTypeCategories(productTypeCategoryFilter: string) {

    if (this.productTypeCategories == null) {
      return;
    }

    this.productTypeCategoriesFiltered = this.productTypeCategories;

    //if (productTypeCategoryFilter == null || productTypeCategoryFilter == "") {
    //  this.productTypeCategoriesFiltered = [];
    //  this.showProductTypeCategoryList = false;
    //  return;
    //}



    let productTypeCategories: dtoProductTypeCategoryGrouped[] = this.productTypeCategories;

    productTypeCategories = productTypeCategories.filter(a => ((a.txtName.toLowerCase().indexOf(productTypeCategoryFilter.toLowerCase()) > -1)));

    productTypeCategories = productTypeCategories.slice(0, 50);

    productTypeCategories.sort((a, b) => { return a.txtName > b.txtName ? 1 : -1 });

    this.productTypeCategoriesFiltered = productTypeCategories;
    //console.log("productTypeCategoriesFiltered", this.productTypeCategoriesFiltered);

    this.showProductTypeCategoryList = true;

    this.setProductTypeCategoryListTranition(1000);

  }

  setProductTypeCategoryListTranition(delay: number) {

    setTimeout(() => {
      this.showProductTypeCategoryListTransition = true;
    }, delay);

  }

  checkMultipleProductTypes(multipleProductTypes: boolean) {

    if (multipleProductTypes == false) {
      //Remove multiple selected productTypes so only one remains.
      let multipleCust: dtoProductTypeCategoryGrouped[] = this.productTypeCategories.filter((a) => { return a.guSelectedProductTypeCategoryId != null && a.guSelectedProductTypeCategoryId != "" });
      if (multipleCust != null && multipleCust.length > 0) {

        multipleCust.sort((a, b) => { return a.txtName > b.txtName ? 1 : -1 });

        for (let i = multipleCust.length - 1; i >= 1; i--) {
          multipleCust.pop();
        }

        for (let i = 0; i <= this.productTypeCategories.length - 1; i++) {
          if (multipleCust[0].rowguid != this.productTypeCategories[i].rowguid) {
            this.productTypeCategories[i].guSelectedProductTypeCategoryId = "";
          }
        }

        this.selectedProductTypeCategories.length = 0;
        this.selectedProductTypeCategories.push(multipleCust[0]);
        this.selectedProductTypeCategoriesEvent.emit(this.selectedProductTypeCategories);

      }

    }
  }

  removeProductType(productTypeCategory: dtoProductTypeCategoryGrouped) {

    if (productTypeCategory == null) {
      return;
    }

    for (let i = this.selectedProductTypeCategories.length - 1; i >= 0; i--) {
      if (this.selectedProductTypeCategories[i].rowguid == productTypeCategory.rowguid) {
        this.selectedProductTypeCategories.splice(i, 1);
        productTypeCategory.guSelectedProductTypeCategoryId = "";
        //console.log("unselected productType");
        this.selectedProductTypeCategoriesEvent.emit(this.selectedProductTypeCategories);
        break;
      }
    }


  }

  expandSelectedProductTypeCategories(e: PointerEvent) {

    if (e.pointerType == null || e.pointerType == "") {
      return;
    }

    this.productTypeCategorySelectionExpanded = !this.productTypeCategorySelectionExpanded;

    let selectedProductTypeCategories: HTMLDivElement = document.getElementById("selectedProductTypeCategories") as HTMLDivElement;
    selectedProductTypeCategories.classList.toggle("selected-product-types-category-expanded");

    if (this.productTypeCategorySelectionExpanded == true) {

    }

  }

  clickPin(e) {

    this.productTypeListPinned = !this.productTypeListPinned;

    if (this.productTypeListPinned == false) {
      this.showProductTypeCategoryList = false;
    }

    this.toolTipText = this.productTypeListPinned == true ? 'unpin' : 'pin';


  }

}
