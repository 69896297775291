import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AuthService } from '../account/auth.service';
import { FileAttachment } from '../_shared/app-objects/file-attachment';
import { BoardTallyRecordHistory } from '../_shared/business-objects/board-tally-record-history.bo';
import { BoardTallyRecordMachineRun } from '../_shared/business-objects/board-tally-record-machine-run.bo';
import { BoardTallyRecordMoulder } from '../_shared/business-objects/board-tally-record-moulder.bo';
import { BoardTallyRecordProductProfile } from '../_shared/business-objects/board-tally-record-product-profile.bo';
import { BoardTallyRecord } from '../_shared/business-objects/board-tally-record.bo';
import { dtoProductCode } from '../_shared/business-objects/dto-product-code.bo';
import { dtoProductCodes } from '../_shared/business-objects/dto-product-codes.bo';
import { dtoStockItem } from '../_shared/business-objects/dto-stock-item.bo';
import { GeoStockLocation } from '../_shared/business-objects/geo-stock-location.bo';
import { Moulder } from '../_shared/business-objects/moulder.bo';
import { ProductCode } from '../_shared/business-objects/product-code.bo';
import { DBFieldFunction, SQLParamArray } from '../_shared/business-objects/sql-param-array';
import { StockContainerType } from '../_shared/business-objects/stock-container-type.bo';
import { StockContainer } from '../_shared/business-objects/stock-container.bo';
import { AlertService } from '../_shared/services/alert.service';
import { FileService } from '../_shared/services/app-services/file.service';
import { BoardTallyRecordMachineRunService } from '../_shared/services/board-tally-record-machine-run.service';
import { BoardTallyRecordMoulderService } from '../_shared/services/board-tally-record-moulder.service';
import { BoardTallyRecordProductProfileService } from '../_shared/services/board-tally-record-product-profile.service';
import { BoardTallyRecordService } from '../_shared/services/board-tally-record.service';
import { FormService } from '../_shared/services/form.service';
import { GeoStockLocationService } from '../_shared/services/geo-stock-location.service';
import { MoulderService } from '../_shared/services/moulder.service';
import { ProductCodeService } from '../_shared/services/product-code.service';
import { SharedService } from '../_shared/services/shared.service';
import { StockContainerTypeService } from '../_shared/services/stock-container-type.service';
import { StockContainerService } from '../_shared/services/stock-container.service';
import { UserPositionService } from '../_shared/services/user-position.service';

@Component({
  selector: 'app-stock-item-update',
  templateUrl: './stock-item-update.component.html',
  styleUrls: ['./stock-item-update.component.css'],
  animations: [
    trigger('displaySpinner', [
      state('open', style({
        opacity: 1 //,
      })),
      state('closed', style({
        opacity: 0 //,
      })),
      transition('closed => open', [
        animate('0.5s', keyframes([
          style({ opacity: 0 }),
          style({ opacity: 1 })
        ]))
      ]),
      transition('open => closed', [
        animate('0.5s', keyframes([
          style({ opacity: 1 }),
          style({ opacity: 0 })
        ]))
      ])

    ])
  ]
})
export class StockItemUpdateComponent implements OnInit {

  @Input() boardTallyRecordHistory: dtoStockItem;
  @Output() stockUpdated = new EventEmitter<true>();
  @ViewChild('quantityUOM') quantityUOM: ElementRef;

  form: FormGroup;

  geoStockLocations: GeoStockLocation[];
  myGeoStockLocations: GeoStockLocation[];
  defaultGeoLocation: GeoStockLocation;

  geoLocationDisabled: boolean = true;

  selectedMoulderValue: string;
  selectedStockContainerTypeValue: string;

  moulders: Moulder[];
  stockContainerTypes: StockContainerType[];

  selectedProductCode: dtoProductCodes;

  stockItems: dtoStockItem[] = [];

  productCodes: dtoProductCodes[];

  showFromLocations: boolean = false;

  showSpinner: boolean = false;

  timer;
  keyPressTime: number;

  bpFloor: boolean = false;
  officeAdmin: boolean = false;
  salesStaff: boolean = false;
  coffsSalesStaff: boolean = false;

  constructor(private fb: FormBuilder, private geoStockLocationService: GeoStockLocationService, private moulderService: MoulderService, private authService: AuthService
    , private boardTallyRecordService: BoardTallyRecordService, private productCodeService: ProductCodeService, private alertService: AlertService
    , private stockContanerService: StockContainerService, private stockContanerTypeService: StockContainerTypeService, private sharedService: SharedService
    , private boardTallyRecordMachineRunService: BoardTallyRecordMachineRunService, private boardTallyRecordMoulderService: BoardTallyRecordMoulderService
    , private boardTallyRecordProductProfileService: BoardTallyRecordProductProfileService, private formService: FormService, private cdref: ChangeDetectorRef
    , private stockContainerTypeService: StockContainerTypeService, private fileService: FileService, private userPositionService: UserPositionService  ) {

    this.form = this.fb.group({
      guGeoStockLocationId: [''],
      txtIdentifier: [''],
      guStockContainerTypeId: [''],
      txtLocation: [''],
      guMoulderId: [''],
      txtSKU: [''],
      fltQuantity: [''],
      txtSearchSKU: [''],
      blnSlobStock: [''],
      blnDamaged: [''],
      blnClearance: ['']
    });


  }


  ngOnInit(): void {

    if (this.boardTallyRecordHistory == null) {
      this.alertService.openSnackBar("There is no stock item to update", "Close", "center", "bottom", "", 3000);
      return;
    }

    this.loadPositions();

    this.loadData();

    this.keyPressTime = Date.now();

  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  async loadPositions() {

    this.bpFloor = await this.userPositionService.isInPosition("board plant floor", this.authService.getAppUserEmployeeId());
    this.salesStaff = await this.userPositionService.isInPosition("sales staff", this.authService.getAppUserEmployeeId());
    this.coffsSalesStaff = await this.userPositionService.isInPosition("coffs sales staff", this.authService.getAppUserEmployeeId());
    this.officeAdmin = await this.userPositionService.isInPosition("office admin", this.authService.getAppUserEmployeeId());

    if (this.salesStaff || this.officeAdmin || this.coffsSalesStaff == true) {
      this.geoLocationDisabled = false;
    }

  }

  async loadData() {


    let aParamList: SQLParamArray[][] = [];

    this.moulderService.getMoulderParamArray(aParamList).subscribe(moulders => {
      this.moulders = moulders;

    }, err => {
      console.log(err);
    });

    this.stockContainerTypeService.getStockContainerTypes().subscribe(containerTypes => {

      console.log(containerTypes);

      this.stockContainerTypes = containerTypes;

    }, err => {
      console.log(err);
    });

    this.defaultGeoLocation = await this.getMyDefaultGeoLocation();
    //this.form.controls['guGeoStockLocationId'].setValue(this.defaultGeoLocation.rowguid);

    this.myGeoStockLocations = await this.getMyGeoStockLocations();

    await new Promise<GeoStockLocation[]>(resolve => {
      this.geoStockLocationService.getAllGeoStockLocations().subscribe(geoLocations => {

        this.geoStockLocations = geoLocations;

        resolve(geoLocations);

      }, err => {
        console.log(err);
        resolve(null);
      });
    });

    //console.log("this.boardTallyRecordHistory.guGeoStockLocationId ", this.boardTallyRecordHistory.guGeoStockLocationId)

    this.form.controls['txtIdentifier'].setValue(this.boardTallyRecordHistory.intIdentifier);
    this.form.controls['guGeoStockLocationId'].setValue(this.boardTallyRecordHistory.guGeoStockLocationId);
    this.form.controls['txtLocation'].setValue(this.boardTallyRecordHistory.txtContainerLocation);
    this.form.controls['guStockContainerTypeId'].setValue(this.boardTallyRecordHistory.guStockContainerTypeId);
    this.form.controls['guMoulderId'].setValue(this.boardTallyRecordHistory.guMoulderId);
    this.form.controls['fltQuantity'].setValue(this.boardTallyRecordHistory.fltQuantity);
    this.form.controls['txtSKU'].setValue(this.boardTallyRecordHistory.txtStockKeepingUnit);
    this.form.controls['blnSlobStock'].setValue(this.boardTallyRecordHistory.blnSLOBStock);
    this.form.controls['blnDamaged'].setValue(this.boardTallyRecordHistory.blnDamaged);
    this.form.controls['blnClearance'].setValue(this.boardTallyRecordHistory.blnClearance);

    //console.log("blnSlobStock ", this.boardTallyRecordHistory.blnSLOBStock);
    //console.log("blnClearance ", this.boardTallyRecordHistory.blnClearance);

    if (this.boardTallyRecordHistory.guProductCodeId == "") {
      this.alertService.openSnackBar("There is no product code set for this stock item, please set one before updating.", "Close", "center", "bottom", "", 3500);
      this.formService.setAutoFocusTimeout("txtSearchSKU");
    }

    this.selectedProductCode = await this.productCodeService.getProductCodeByRowguidPromise(this.boardTallyRecordHistory.guProductCodeId);

  }

  async getMyGeoStockLocations() {
    return new Promise<GeoStockLocation[]>(resolve => {
      this.geoStockLocationService.getMyGeoStockLocations(this.authService.getAppUserId()).subscribe(myLocations => {
        this.showFromLocations = true;
        resolve(myLocations);

      }, err => {
        console.log(err);
        resolve(null);
      });
    });



  }

  async getMyDefaultGeoLocation() {

    return new Promise<GeoStockLocation>(resolve => {
      this.geoStockLocationService.getMyDefaultGeoStockLocation(this.authService.getAppUserId()).subscribe(stockLocation => {

        resolve(stockLocation);

      }, err => {
        console.log(err);
      });
    });


  }

  showMyLocations(val) {

    if (this.myGeoStockLocations == null || this.defaultGeoLocation == null) {
      return true;
    }

    for (let i = 0; i <= this.myGeoStockLocations.length - 1; i++) {

      if (this.defaultGeoLocation.rowguid == this.myGeoStockLocations[i].rowguid) {
        return false;
      }
    }
    return true;
  }


  async updateStock() {

    if (this.form.controls['fltQuantity'].value.toString() == "") {
      this.alertService.openSnackBar("Please enter a quantity.", "Close", "center", "bottom", "", 3000);
      return;
    }


    if (this.selectedMoulderValue == null || this.selectedMoulderValue == "") {
      this.alertService.openSnackBar("Please select a moulder.", "Close", "center", "bottom", "", 3000);
      return;
    }

    if (this.selectedStockContainerTypeValue == null || this.selectedStockContainerTypeValue == "") {
      this.alertService.openSnackBar("Please select a stock container type.", "Close", "center", "bottom", "", 3000);
      return;
    }

    if (this.selectedProductCode == null) {
      this.alertService.openSnackBar("Please enter a SKU.", "Close", "center", "bottom", "", 3000);
      return;
    }


    let quantity: number = parseFloat(this.form.controls['fltQuantity'].value);
    let location: string = this.form.controls['txtLocation'].value.toString();
    let geoStockLocation: string = this.form.controls['guGeoStockLocationId'].value.toString();
    let stockContainer: StockContainer = await this.stockContanerService.getStockContainerPromise(this.boardTallyRecordHistory.guStockContainerId);
    let boardTallyRecord: BoardTallyRecord = await this.boardTallyRecordService.getBoardTallyRecordPromise(this.boardTallyRecordHistory.rowguid);
    let boardTallyRecordMoulder: BoardTallyRecordMoulder;
    let boardTallyRecordMoulders: BoardTallyRecordMoulder[] = [];
    let boardTallyRecordMachineRun: BoardTallyRecordMachineRun;
    let boardTallyRecordProfile: BoardTallyRecordProductProfile;
    let quantityChanged: boolean = false;
    let locationChanged: boolean = false;
    let moulderChanged: boolean = false;
    let createContainerHistory: boolean = false;
    let createBoardTallyHistory: boolean = false;
    let stockContainerReason: BoardTallyRecordHistory.enChangeReason = BoardTallyRecordHistory.enChangeReason.Location;  // default to location, it wont get used if createHistory is set to false.
    let boardTallyRecordReason: BoardTallyRecordHistory.enChangeReason = BoardTallyRecordHistory.enChangeReason.Quantity;  // default to quantity, it wont get used if createHistory is set to false.
    let slobStock: boolean = this.form.controls['blnSlobStock'].value == true;
    let damagedStock: boolean = this.form.controls['blnDamaged'].value == true;
   let clearanceStock: boolean = this.form.controls['blnClearance'].value == true;

    //let stockContainerType: StockContainerType = await this.stockContanerTypeService.getStockContainerByNamePromise("Pack");

    console.log(stockContainer);
    console.log(boardTallyRecord);
    console.log("slobStock", slobStock);
    console.log("clearanceStock", clearanceStock);
    console.log("boardTallyRecord.blnClearance", boardTallyRecord.blnClearance);
    console.log("this.form.controls['blnClearance'].value", this.form.controls['blnClearance'].value);

    if (quantity == boardTallyRecord.fltQuantity && location == stockContainer.txtLocation && this.selectedMoulderValue == boardTallyRecord.guMoulderId && this.selectedProductCode.rowguid == boardTallyRecord.guProductCodeId && this.selectedStockContainerTypeValue == stockContainer.guStockContainerTypeId && geoStockLocation == stockContainer.guGeoStockLocationId && stockContainer.blnSLOBStock == slobStock && boardTallyRecord.blnClearance == clearanceStock && stockContainer.blnDamaged == damagedStock) {
      // Nothing has changed.
      this.alertService.openSnackBar("No data has changed, is this correct?", "Close", "center", "bottom", "", 3000);
      return;
    }



    if (location != stockContainer.txtLocation || stockContainer.guGeoStockLocationId != geoStockLocation) {
      // Location has changed.
      locationChanged = true;
      createContainerHistory = true;
      stockContainerReason = BoardTallyRecordHistory.enChangeReason.Location;

      stockContainer.txtLocation = location;
      stockContainer.dteLastModified = this.sharedService.currentDatePlusTZOffset();
      stockContainer.guGeoStockLocationId = this.form.controls['guGeoStockLocationId'].value.toString();
      stockContainer.blnSLOBStock = slobStock;
      stockContainer.blnDamaged = damagedStock;
      stockContainer = await this.stockContanerService.updateStockContainerPromise(this.authService.getAppUserEmployeeId(), false, stockContainerReason, stockContainer);
    }

    if (slobStock != stockContainer.blnSLOBStock || damagedStock != stockContainer.blnDamaged) {
      stockContainer.blnSLOBStock = slobStock;
      stockContainer.blnDamaged = damagedStock;
      stockContainer = await this.stockContanerService.updateStockContainerPromise(this.authService.getAppUserEmployeeId(), false, stockContainerReason, stockContainer);
    }

    if (this.selectedProductCode.rowguid != boardTallyRecord.guProductCodeId) {
      console.log(this.selectedProductCode);

      boardTallyRecordReason = BoardTallyRecordHistory.enChangeReason.ProductCode;

      boardTallyRecord.dteDate = this.sharedService.currentDatePlusTZOffset();
      boardTallyRecord.guProductCodeId = this.selectedProductCode.rowguid;
      boardTallyRecord.guProductProfileId = this.selectedProductCode.guProfileId;
      boardTallyRecord.guProfileId = this.selectedProductCode.guProductTypeId;
      boardTallyRecord.guMoulderId = this.selectedMoulderValue;
      boardTallyRecord.intWidth = this.selectedProductCode.fltWidth;
      boardTallyRecord.intThickness = this.selectedProductCode.fltThickness;
      boardTallyRecord.fltNominalWidth = this.selectedProductCode.fltStartWidth;
      boardTallyRecord.fltNominalThickness = this.selectedProductCode.fltStartThickness;
      boardTallyRecord.fltExWidth = this.selectedProductCode.fltStartWidth;
      boardTallyRecord.fltExThickness = this.selectedProductCode.fltStartThickness;
      boardTallyRecord.guSpeciesId = this.selectedProductCode.guSpeciesId;
      boardTallyRecord.guBoardGradeId = this.selectedProductCode.guGradeId;
      boardTallyRecord.intTotalLM = this.selectedProductCode.fltLength;
      boardTallyRecord.intQuantityUoM = this.selectedProductCode.intDefaultQtyUoM;
      boardTallyRecord.fltDia = this.selectedProductCode.fltDia;
      boardTallyRecord.blnClearance = clearanceStock;
      boardTallyRecord = await this.boardTallyRecordService.updateBoardTallyRecordPromise(this.authService.getAppUserEmployeeId(), createBoardTallyHistory, boardTallyRecordReason, boardTallyRecord);

      let aParamList: SQLParamArray[][] = [];
      let aParam: SQLParamArray[] = [];
      aParam.push(new SQLParamArray("guBoardTallyRecordId", boardTallyRecord.rowguid));
      aParamList.push(aParam);

      // Remove all profiles then add the selected one.
      let boardTallyRecordProfiles = await this.boardTallyRecordProductProfileService.getAllBoardTallyRecordProductProfilesParamArrayPromise(aParamList);
      for (let i = 0; i <= boardTallyRecordProfiles.length - 1; i++) {
        await this.boardTallyRecordProductProfileService.deleteBoardTallyRecordProductProfilePromise(boardTallyRecordProfiles[i].rowguid);
      }

      if (this.selectedProductCode.guProfileId != null) {
        boardTallyRecordProfile = new BoardTallyRecordProductProfile;
        boardTallyRecordProfile.guProductProfileId = this.selectedProductCode.guProfileId;
        boardTallyRecordProfile.guBoardTallyRecordId = boardTallyRecord.rowguid;
        await this.boardTallyRecordProductProfileService.createBoardTallyRecordProductProfilePromise(boardTallyRecordProfile);
      }

    }

    if ((quantity != boardTallyRecord.fltQuantity) || (this.selectedMoulderValue != boardTallyRecord.guMoulderId)) {


      if (quantity != boardTallyRecord.fltQuantity) {
        // Quantity has changed.
        quantityChanged = true;
        createBoardTallyHistory = true;
        boardTallyRecordReason = BoardTallyRecordHistory.enChangeReason.Quantity;
        boardTallyRecord.dteDate = this.sharedService.currentDatePlusTZOffset();
        boardTallyRecord.fltQuantity = quantity;

        // UPDATE BOARD TALLY RECORD EACH TIME SO WE CREATE A HISTORY RECORD.
        boardTallyRecord = await this.boardTallyRecordService.updateBoardTallyRecordPromise(this.authService.getAppUserEmployeeId(), createBoardTallyHistory, boardTallyRecordReason, boardTallyRecord);
      }

      if (this.selectedMoulderValue != boardTallyRecord.guMoulderId) {
        // Moulder has changed.
        let aParamList: SQLParamArray[][] = [];
        let aParam: SQLParamArray[] = [];
        aParam.push(new SQLParamArray("guBoardTallyRecordId", boardTallyRecord.rowguid));
        aParamList.push(aParam);

        boardTallyRecordReason = BoardTallyRecordHistory.enChangeReason.Moulder;
        moulderChanged = true;
        boardTallyRecord.guMoulderId = this.selectedMoulderValue;

        // Remove all moulders then add the selected one.
        boardTallyRecordMoulders = await this.boardTallyRecordMoulderService.getAllBoardTallyRecordMouldersParamArrayPromise(aParamList);
        if (boardTallyRecordMoulders != null) {
          for (let i = 0; i <= boardTallyRecordMoulders.length - 1; i++) {
            await this.boardTallyRecordMoulderService.deleteBoardTallyRecordMoulderPromise(boardTallyRecordMoulders[i].rowguid);
          }
        }

        boardTallyRecordMoulder = new BoardTallyRecordMoulder;
        boardTallyRecordMoulder.guMoulderId = this.selectedMoulderValue;
        boardTallyRecordMoulder.guBoardTallyRecordId = boardTallyRecord.rowguid;
        boardTallyRecordMoulder.intNoOfRuns = 1;
        await this.boardTallyRecordMoulderService.createBoardTallyRecordMoulderPromise(boardTallyRecordMoulder);

        // UPDATE BOARD TALLY RECORD EACH TIME SO WE CREATE A HISTORY RECORD.
        boardTallyRecord = await this.boardTallyRecordService.updateBoardTallyRecordPromise(this.authService.getAppUserEmployeeId(), createBoardTallyHistory, boardTallyRecordReason, boardTallyRecord);
      }

    }

    if (clearanceStock != boardTallyRecord.blnClearance) {
      console.log("boardtallyRecord: ", boardTallyRecord);
      console.log("clearanceStock: ", clearanceStock);

      boardTallyRecord.blnClearance = clearanceStock;
      console.log("boardtallyRecord: ", boardTallyRecord);
     boardTallyRecord = await this.boardTallyRecordService.updateBoardTallyRecordPromise(this.authService.getAppUserEmployeeId(), false, boardTallyRecordReason, boardTallyRecord);
    }


    if (this.selectedStockContainerTypeValue != stockContainer.guStockContainerTypeId) {
      // Stock Container Type has changed.
      stockContainer.guStockContainerTypeId = this.selectedStockContainerTypeValue;
      stockContainer = await this.stockContanerService.updateStockContainerPromise(this.authService.getAppUserEmployeeId(), false, BoardTallyRecordHistory.enChangeReason.StockContainerType, stockContainer);
    }

    this.stockUpdated.emit(true);

    // =============================================================================
    // NEED TO CREATE BOARD TALLY RECORD PRODUCT PROFILE 
    // =============================================================================


    //for (let i = 0; i <= this.selectedMoulders.length - 1; i++) {

    //}



  }


  disablingIdentifier(chkUseAutoNumbers, txtIdentifier) {
    if (chkUseAutoNumbers == null || txtIdentifier == null) {
      return;
    }

    if (chkUseAutoNumbers.checked == true) {
      txtIdentifier.value = "";
    }
  }

  getProductCodes(sku: string) {

    this.productCodes = null;
    this.selectedProductCode = null;

    if (sku.length < 2) {
      return;
    }



    let seconds = (Date.now() - this.keyPressTime);
    if (seconds < 1000) {
      clearTimeout(this.timer);
    }

    this.timer = setTimeout(this.loadProductCodes, 1000, sku, this);

    this.keyPressTime = Date.now();

  }


  loadProductCodes(sku: string, oThis) {

    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];
    aParam.push(new SQLParamArray("txtStockKeepingUnit", sku, SQLParamArray.enSQLOperator.Like))
    aParam.push(new SQLParamArray("txtStockKeepingUnit", sku, SQLParamArray.enSQLOperator.Like, SQLParamArray.enSQLLogicalOperator.AND, new DBFieldFunction(SQLParamArray.enDBFieldFunction.Replace, "-", "")))
    aParamList.push(aParam);

    aParam = [];
    aParam.push(new SQLParamArray("blnActive", "1"));
    aParamList.push(aParam);


    oThis.showSpinner = true;
    oThis.productCodeService.getProductCodesParamArray(aParamList).subscribe(productCodes => {

      oThis.showSpinner = false;

      oThis.productCodes = productCodes;


    }, err => {
      console.log(err);
    });


  }


  selectedProductCodes(productCodes: dtoProductCodes[]) {

    if (productCodes == null || productCodes.length == 0) {
      return;
    }

    this.form.controls['txtSKU'].setValue(productCodes[0].txtStockKeepingUnit);
    this.quantityUOM.nativeElement.innerHTML = productCodes[0].txtQuantityUoM;
    this.selectedProductCode = productCodes[0];

    console.log(productCodes);

  }


}
