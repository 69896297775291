import { trigger, state, style, transition, animate, keyframes } from '@angular/animations';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { enDeliveryDocketTabs } from '../../delivery-docket/delivery-docket-search/delivery-docket-search.component';
import { Client } from '../../_shared/business-objects/client.bo';
import { AlertService } from '../../_shared/services/alert.service';
import { FormService } from '../../_shared/services/form.service';
import { SharedService } from '../../_shared/services/shared.service';
import { dtoOrderItemService } from '../../_shared/services/dto-order-item.service';
import { OrderItem } from '../../_shared/business-objects/order-item.bo';
import { dtoCustomerService } from '../../_shared/services/dto-customer.service';
import { dtoOrdersService } from '../../_shared/services/dto-orders.service';
import { dtoCustomer } from '../../_shared/business-objects/dto-customer.bo';
import { SQLParamArray } from '../../_shared/business-objects/sql-param-array';
import { dtoOrders } from '../../_shared/business-objects/dto-orders.bo';
import { Orders } from '../../_shared/business-objects/orders.bo';
import { MatTableDataSource } from '@angular/material/table';
import { dtoOrderItem } from '../../_shared/business-objects/dto-order-item.bo';
import { dtoStockItem } from '../../_shared/business-objects/dto-stock-item.bo';
import { OrderItemBarcodeStickerComponent } from '../../report-centre/reports/order-item-barcode-sticker/order-item-barcode-sticker.component';
import { StockContainer } from '../../_shared/business-objects/stock-container.bo';
import { StockContainerTypeService } from '../../_shared/services/stock-container-type.service';
import { StockContainerType } from '../../_shared/business-objects/stock-container-type.bo';
import { StockContainerService } from '../../_shared/services/stock-container.service';
import { AuthService } from '../../account/auth.service';
import { BoardTallyRecordHistory } from '../../_shared/business-objects/board-tally-record-history.bo';
import { BoardTallyRecord } from '../../_shared/business-objects/board-tally-record.bo';
import { BoardTallyRecordService } from '../../_shared/services/board-tally-record.service';
import { BoardTallyRecordMoulderService } from '../../_shared/services/board-tally-record-moulder.service';
import { BoardTallyRecordProductProfileService } from '../../_shared/services/board-tally-record-product-profile.service';
import { BoardTallyRecordMoulder } from '../../_shared/business-objects/board-tally-record-moulder.bo';
import { BoardTallyRecordProductProfile } from '../../_shared/business-objects/board-tally-record-product-profile.bo';
import { NavigationService } from '../../_shared/services/navigation.service';
import { BoardTallyRecordMachineRun } from '../../_shared/business-objects/board-tally-record-machine-run.bo';
import { BoardTallyRecordMachineRunService } from '../../_shared/services/board-tally-record-machine-run.service';
import { BpFloorOrderItemStockItemListComponent } from '../bp-floor-order-item-stock-item-list/bp-floor-order-item-stock-item-list.component';
import { BpFloorOrderItemListComponent } from '../bp-floor-order-item-list/bp-floor-order-item-list.component';
import { MatCheckbox } from '@angular/material/checkbox';
import { StockItemContainer } from '../../_shared/business-objects/stock-item-container';
import { StockItem } from '../../fetch-data/fetch-data.component';
import { BoardTallyRecordOrderItemService } from '../../_shared/services/board-tally-record-order-item.service';
import { BoardTallyRecordOrderItem } from '../../_shared/business-objects/board-tally-record-order-item.bo';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-bp-floor-order-search',
  templateUrl: './bp-floor-order-search.component.html',
  styleUrls: ['./bp-floor-order-search.component.css'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('displaySpinner', [
      state('open', style({
        opacity: 1 //,
      })),
      state('closed', style({
        opacity: 0 //,
      })),
      transition('closed => open', [
        animate('0.5s', keyframes([
          style({ opacity: 0 }),
          style({ opacity: 1 })
        ]))
      ]),
      transition('open => closed', [
        animate('0.5s', keyframes([
          style({ opacity: 1 }),
          style({ opacity: 0 })
        ]))
      ])

    ])
  ]
})
export class BpFloorOrderSearchComponent implements OnInit {

  @ViewChild('orderItemBarcodeSticker') orderItemBarcodeSticker: OrderItemBarcodeStickerComponent;
  @ViewChild('appOrderItemList') appOrderItemList: BpFloorOrderItemListComponent;

  @ViewChild('chkViewAllOrders') chkViewAllOrders: MatCheckbox;

  form: FormGroup;
  public eDeliveryDocketTabs = enDeliveryDocketTabs;

  totalTon: number = 0;
  totalM3: number = 0;
  totalQuantity: number = 0;
  totalRows: number = 0;

  resultTime: number;
  searchTime: number;

  selectedTab: number;

  showSpinner: boolean = false;
  showOrderSpinner: boolean = false;
  showOrderItemSpinner: boolean = false;

  customers: dtoCustomer[];
  customersAll: dtoCustomer[];
  orders: dtoOrders[];
  orderItems: MatTableDataSource<dtoOrderItem>;
  orderItem: dtoOrderItem;

  stockItems: dtoStockItem[];
  stockItemContainer: StockItemContainer[] = [];
  blankStockItem: dtoStockItem;

  timer;
  keyPressTime: number;
  selectedCustomer: dtoCustomer;


  constructor(private dtoOrderItemService: dtoOrderItemService, private fb: FormBuilder, private sharedService: SharedService, private alertService: AlertService
    , private formService: FormService, private dtoCustomerService: dtoCustomerService, private dtoOrdersService: dtoOrdersService
    , private stockContanerService: StockContainerService, private stockContanerTypeService: StockContainerTypeService, private authService: AuthService
    , private boardTallyRecordService: BoardTallyRecordService, private boardTallyRecordMoulderService: BoardTallyRecordMoulderService
    , private boardTallyRecordProductProfileService: BoardTallyRecordProductProfileService, private navigationService: NavigationService
    , private boardTallyRecordMachineRunService: BoardTallyRecordMachineRunService, private boardTallyRecordOrderItemService: BoardTallyRecordOrderItemService  ) {


    this.form = this.fb.group({
      guCustomerId: [''],
      guOrderId: [''],
      radOrderItemStatus: ['']
    });

  }

  ngOnInit(): void {
    //this.showSpinner = true;
    //this.showOrderSpinner = true;

    //let stockItem: dtoStockItem = new dtoStockItem();
    //this.stockItems = [];
    //this.stockItems.push(stockItem);


    this.loadData();

    // Testing only
    //setTimeout(async function (oThis) {
    //  oThis.chkViewAllOrders.checked = true;
    //  oThis.form.controls['guCustomerId'].setValue(["9a9ed49b-09a7-4bc0-a8a1-d79476fa784e"]);

    //  await oThis.getActiveAndPartialCompleteOrders(["9a9ed49b-09a7-4bc0-a8a1-d79476fa784e"]);

    //}, 3000, this);


  }

  async loadData() {
    //*******************************************************************************************************************
    // TESTING
    //*******************************************************************************************************************

    //this.orderItem = await this.dtoOrderItemService.getdtoOrderItemPromise("280d7165-6a1c-4d27-90a8-765146517d0d");

    //this.orderItems = new MatTableDataSource<dtoOrderItem>();

    //let aParamList2: SQLParamArray[][] = [];
    //let aParam2: SQLParamArray[] = [];
    //aParam2.push(new SQLParamArray("guOrderId", "15e5ae51-6f26-4269-bd9c-5faf7912104e"));
    //aParamList2.push(aParam2);

    //this.orderItems.data = await this.dtoOrderItemService.getdtoOrderItemParamArrayPromise(aParamList2);

    //console.log(this.orderItem);

    // For testing only. Popping up multiple dialog to test.
    //setTimeout(()=>{ this.appOrderItemList.addMultipleLength(this.orderItem, 0) }, 2000);

    //*******************************************************************************************************************


    //this.form.controls['guCustomerId'].setValue(["07cf495c-38c3-440c-bae5-34e7500dcaa5"]);

    //await this.getActiveAndPartialCompleteOrders(["07cf495c-38c3-440c-bae5-34e7500dcaa5"]);
    //await this.getOrderItems(["494140d1-0a29-4090-827f-35169dce7c82"]);
    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    aParam.push(new SQLParamArray("blnActive", "1"));
    aParamList.push(aParam);

    //aParam = [];
    //aParam.push(new SQLParamArray("rowguid", "9A9ED49B-09A7-4BC0-A8A1-D79476FA784E"));
    //aParamList.push(aParam);

    this.showSpinner = true;

    this.customersAll = await this.dtoCustomerService.getdtoCustomerParamArrayPromise(aParamList);
    this.customersAll.sort((a, b) => { return a.txtName > b.txtName ? 1 : -1 });

    this.customers = this.customersAll;

    this.showSpinner = false;

    //await this.form.controls['guCustomerId'].setValue(["9A9ED49B-09A7-4BC0-A8A1-D79476FA784E"]);

    //await this.getActiveAndPartialCompleteOrders(["9A9ED49B-09A7-4BC0-A8A1-D79476FA784E"]);
    //await this.getOrderItems(["422254DB-A23C-4715-B18C-008D873A1CA0"]);

    //
  }

  async getCustomers() {
    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    aParam.push(new SQLParamArray("blnActive", "1"));
    aParamList.push(aParam);

    return await this.dtoCustomerService.getdtoCustomerParamArrayPromise(aParamList);

  }


  onTabChange(index: number) {


  }


  async searchCustomer(name: string, othis) {

    //console.log("name: " + name);

    //console.log(othis.customersAll);


    if (name == "") {
      othis.customers = othis.customersAll;
      othis.showSpinner = false;
      return;
    }

    //othis.customers = othis.customersAll.filter(customer => { customer.txtName.toLowerCase().startsWith(name.toLowerCase()) });
    const result = othis.customersAll.filter(cus => cus.txtName.toLowerCase().startsWith(name.toLowerCase()));

    othis.customers = result;

    //let aParamList: SQLParamArray[][] = [];
    //let aParam: SQLParamArray[] = [];

    //aParam.push(new SQLParamArray("txtName", name, SQLParamArray.enSQLOperator.Like));
    //aParamList.push(aParam);

    //othis.customers = await othis.dtoCustomerService.getdtoCustomerParamArrayPromise(aParamList);

    //console.log(othis.customers);
    othis.showSpinner = false;

  }

  getCustomerSearchByName(name: string) {

    this.showSpinner = true;

    //if (name == "") {
    //  this.customers = this.customersAll;
    //  return;
    //}

    //const result = this.customersAll.filter(cus => cus.txtName.toLowerCase().startsWith(name.toLowerCase()));

    //this.customers = result;


    //console.log(this.customersAll);
    //console.log(this.customers);


    this.customers = null;
    this.selectedCustomer = null;

    //if (name.length < 1) {
    //  this.showSpinner = false;
    //  return;
    //}



    let seconds = (Date.now() - this.keyPressTime);
    if (seconds < 1000) {
      clearTimeout(this.timer);
    }

    this.timer = setTimeout(this.searchCustomer, 1000, name, this);

    this.keyPressTime = Date.now();

  }

  async getActiveAndPartialCompleteOrders(customerId: string[]) {
    //console.log("customerId: ", customerId);

    if (customerId == null || Array.isArray(customerId) == false || customerId[0] == "") {
      this.alertService.openSnackBarDefault("Please select a customer");
      return;
    }

    this.showOrderSpinner = true;

    let custId: string = customerId[0];


    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    aParam.push(new SQLParamArray("guCustomerId", custId));
    aParamList.push(aParam);

    aParam = [];
    aParam.push(new SQLParamArray("intType", Orders.enType.Order.toString()));
    aParamList.push(aParam);

    if (this.chkViewAllOrders.checked == false) {
      aParam = [];
      aParam.push(new SQLParamArray("intStatus", Orders.enStatus.Active.toString()));
      aParam.push(new SQLParamArray("intStatus", Orders.enStatus.PartialComplete.toString()));
      aParamList.push(aParam);
    }

    this.orders = await this.dtoOrdersService.getdtoOrdersParamArrayPromise(aParamList);
    this.orders.sort((a, b) => { return a.dteCreated <= b.dteCreated ? 1 : -1 });

    this.showOrderSpinner = false;

  }

  async getOrderItems(orderId: string[]) {

    //console.log("orderId: ", orderId);

    if (orderId == null || Array.isArray(orderId) == false || orderId[0] == "") {
      this.alertService.openSnackBarDefault("Please select a order");
      return;
    }


    this.showOrderItemSpinner = true;

    let ordId: string = orderId[0];

    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];

    aParam.push(new SQLParamArray("guOrderId", ordId));
    aParamList.push(aParam);
    this.orderItems = new MatTableDataSource<dtoOrderItem>();

    //console.log("going to get orderItems");

    this.orderItems.data = await this.dtoOrderItemService.getdtoOrderItemParamArrayPromise(aParamList);

    //console.log("orderItems", this.orderItems.data);

    this.showOrderItemSpinner = false;

  }


  searchOrderItems(type: string, top: number) {

  }

  selectedOrderItems(orderItems: dtoOrderItem[]) {
    //console.log("in selectedOrderItems: ", orderItems);

    if (orderItems == null || orderItems.length == 0) {
      return;
    }


    this.orderItem = orderItems[0];

  }


  addStockItem(stockItemContainer: StockItemContainer[]) {

    this.stockItems = [];

    this.stockItemContainer = stockItemContainer;

    this.stockItemContainer.forEach(s => {

      s.stockItem.intMultipleLengthCount = s.boardTallyRecordOrderItemArray.length;
      //console.log("s.boardTallyRecordOrderItemArray.length: " + s.boardTallyRecordOrderItemArray.length);

      let iCount: number = 0;
      let multipleLength: string = "";
      s.boardTallyRecordOrderItemArray.forEach(btoi => {
        if (btoi.guBoardTallyRecordId == s.stockItem.rowguid) {

          //console.log("btoi.fltOrderItemLength: ", btoi.fltOrderItemLength);
          if (btoi.fltBTOrderItemQuantity > 1) {
            multipleLength += btoi.fltBTOrderItemQuantity.toString() + " x ";
          }
          multipleLength += btoi.fltOrderItemLength.toString();

          if (iCount < s.boardTallyRecordOrderItemArray.length - 1) {
            multipleLength += ", ";
          }
          iCount++;
        }

      });

      s.stockItem.txtMultipleLength = multipleLength;

      console.log("stockItem: ", s.stockItem);

      this.stockItems.push(s.stockItem);
    });


    //Reset intItemNo
    for (let i = 0; i <= this.stockItemContainer.length - 1; i++) {
      //console.log("addStockItem - i: " + i);
      this.stockItemContainer[i].stockItem.intItemNo = i + 1;
    }

    this.orderItemBarcodeSticker.loadStockItems(this.stockItems);

  }

  removeStockItem(stockItem: dtoStockItem) {

    this.stockItems = [];

    let orderItemIdRemoved: string = "";

    for (let i = 0; i <= this.stockItemContainer.length - 1; i++) {
      if (this.stockItemContainer[i].stockItem.rowguid == stockItem.rowguid) {
        this.orderItems.data.forEach(oi => {
          this.stockItemContainer[i].boardTallyRecordOrderItemArray.forEach(btoi => {
            if (oi.rowguid == btoi.guOrderItemId) {
              //console.log("removeStockItem - fltOrderItemQuantity: ", btoi);
              oi.fltQtyAdded -= parseFloat(btoi.fltBTOrderItemQuantity.toString());
              orderItemIdRemoved = oi.rowguid;
            }
          });
        });
        this.stockItemContainer.splice(i, 1);
        break;
      }
    }

    //console.log("this.stockItemContainer: ", this.stockItemContainer);

    //Reset intItemNo
    for (let i = 0; i <= this.stockItemContainer.length - 1; i++) {
      //console.log("addStockItem - i: " + i);
      this.stockItemContainer[i].stockItem.intItemNo = i + 1;
      this.stockItems.push(this.stockItemContainer[i].stockItem);
    }


    this.orderItemBarcodeSticker.loadStockItems(this.stockItems);


    this.appOrderItemList.resetAddToPackButton(orderItemIdRemoved);

  }



  async createPackAndPrint() {

    if (this.stockItems == null || this.stockItems.length == 0) {
      await this.alertService.openSnackBarDefault("There are no stock items to create.");
      return;
    }

    let stockContainer: StockContainer = new StockContainer();
    let stockContainerType: StockContainerType = await this.stockContanerTypeService.getStockContainerByNamePromise("Pack");
    let boardTallyRecord: BoardTallyRecord = new BoardTallyRecord;
    let boardTallyRecordMoulder: BoardTallyRecordMoulder;
    let boardTallyRecordProfile: BoardTallyRecordProductProfile;
    let boardTallyRecordMachineRun: BoardTallyRecordMachineRun;

    delete stockContainer.rowguid;  //remove rowguid property so DB will create one.
    stockContainer.intIdentifier = 0;
    stockContainer.guStockContainerTypeId = stockContainerType.rowguid;
    stockContainer.txtLocation = "P-0-6";  // This is the orders pile. will default to here.
    stockContainer.dteCreated = this.sharedService.currentDatePlusTZOffset();
    stockContainer.dteOriginalDateCreated = this.sharedService.currentDatePlusTZOffset();
    stockContainer.dteLastModified = this.sharedService.currentDatePlusTZOffset();
    stockContainer.dteStockTake = null;
    stockContainer.intStatus = StockContainer.enStatus.Active;
    stockContainer.blnArchived = false;
    stockContainer.guGeoStockLocationId = this.navigationService.getLocationGuid("BP"); // This may need to be dependant on who you're logged on as, could be used for Lawson Crescent as well.


    let res: StockContainer | HttpErrorResponse;
    res = await this.stockContanerService.createStockContainerPromise(this.authService.getAppUserEmployeeId(), false, BoardTallyRecordHistory.enChangeReason.Created, true, stockContainer);

    if (res instanceof HttpErrorResponse) {
      stockContainer = null;

      //console.log("res.error: ", res.error);
      //console.log("res.error.indexOf: ", res.error.indexOf("The manual identifier number is higher than"));

      //Something went wrong creating the stock container so abort.
      let msg: string = "There was an error creating the stock container"
      if (res.error.indexOf("The manual identifier number is higher than") > -1) {
        msg += "\nPerhaps the manual pack numer is higher than 149999. Please check your number."
      }
      await this.alertService.openSnackBarCustomDefaultPromise("Error Creating Stock Container", msg, "Ok");
      return;
    }

    console.log("res: ", res);
    console.log("res instanceof StockContainer: ", res instanceof StockContainer);
    stockContainer = res;

    if (stockContainer == null) {
      this.alertService.openSnackBarError("Error Creating Stock Container", "Close", "center", "bottom", 4000, true, "There was an error creating the stock container in bp floor order");
      return;
    }

    for (let i = 0; i <= this.stockItemContainer.length - 1; i++) {
        

      //delete boardTallyRecord.rowguid;  // We are using the Javascript create GUID property.
      //                                  // It is used to link the in memory moulders and machine runs to this board tally record.
      boardTallyRecord.rowguid = this.stockItemContainer[i].stockItem.rowguid;
      boardTallyRecord.intItemNo = this.stockItemContainer[i].stockItem.intItemNo;
      boardTallyRecord.guStockContainerId = stockContainer.rowguid;
      boardTallyRecord.dteDateCreated = this.sharedService.currentDatePlusTZOffset();
      boardTallyRecord.dteDate = this.sharedService.currentDatePlusTZOffset();
      boardTallyRecord.fltQuantity = parseFloat(this.stockItemContainer[i].stockItem.fltQuantity.toString());
      boardTallyRecord.intQuantityUoM = this.stockItemContainer[i].stockItem.intQuantityUoM;
      //boardTallyRecord.guProductCodeId = this.selectedProductCode.rowguid;
      boardTallyRecord.guProductProfileId = this.stockItemContainer[i].stockItem.guProductProfileId;
      boardTallyRecord.guProfileId = this.stockItemContainer[i].stockItem.guProfileId;
      boardTallyRecord.guMoulderId = this.stockItemContainer[i].stockItem.guMoulderId;
      boardTallyRecord.intWidth = this.stockItemContainer[i].stockItem.intWidth;
      boardTallyRecord.intThickness = this.stockItemContainer[i].stockItem.intThickness;
      boardTallyRecord.fltNominalWidth = this.stockItemContainer[i].stockItem.fltNominalWidth;
      boardTallyRecord.fltNominalThickness = this.stockItemContainer[i].stockItem.fltNominalThickness;
      boardTallyRecord.fltExWidth = this.stockItemContainer[i].stockItem.fltExWidth;
      boardTallyRecord.fltExThickness = this.stockItemContainer[i].stockItem.fltExThickness;
      boardTallyRecord.guSpeciesId = this.stockItemContainer[i].stockItem.guSpeciesId;
      boardTallyRecord.guBoardGradeId = this.stockItemContainer[i].stockItem.guBoardGradeId;
      boardTallyRecord.intTotalLM = this.stockItemContainer[i].stockItem.intTotalLM;
      boardTallyRecord.fltDia = this.stockItemContainer[i].stockItem.fltDia;
      boardTallyRecord.guCustomerId = this.stockItemContainer[i].stockItem.guCustomerId;
      boardTallyRecord.guBoardOrderId = this.stockItemContainer[i].stockItem.guBoardOrderId;
      boardTallyRecord.guOrderItemId = this.stockItemContainer[i].stockItem.guOrderItemId;
      boardTallyRecord.guProductCodeId = this.stockItemContainer[i].stockItem.guProductCodeId;
      boardTallyRecord.blnActual = true;
      boardTallyRecord.blnArchive = false;

      console.log("before Create: ", boardTallyRecord);

      boardTallyRecord = await this.boardTallyRecordService.createBoardTallyRecordPromise(this.authService.getAppUserEmployeeId(), true, BoardTallyRecordHistory.enChangeReason.Created, boardTallyRecord);

      console.log("after Create: ", boardTallyRecord);

      if (this.stockItemContainer[i].boardTallyRecordMoulderArray != null) {
        this.stockItemContainer[i].boardTallyRecordMoulderArray.forEach(async btrm => {
          //boardTallyRecordMoulder = new BoardTallyRecordMoulder;
          //boardTallyRecordMoulder.guMoulderId = this.stockItemContainer[i].stockItem.guMoulderId;
          //boardTallyRecordMoulder.guBoardTallyRecordId = boardTallyRecord.rowguid;
          //boardTallyRecordMoulder.intNoOfRuns = 1;

          await this.boardTallyRecordMoulderService.createBoardTallyRecordMoulderPromise(btrm);

        })
      }

      // =============================================================================
      // NEED TO CREATE BOARD TALLY RECORD PRODUCT PROFILE 
      // =============================================================================

      boardTallyRecordProfile = new BoardTallyRecordProductProfile;
      boardTallyRecordProfile.guProductProfileId = this.stockItemContainer[i].stockItem.guProductProfileId;
      boardTallyRecordProfile.guBoardTallyRecordId = boardTallyRecord.rowguid;
      await this.boardTallyRecordProductProfileService.createBoardTallyRecordProductProfilePromise(boardTallyRecordProfile);

      if (this.stockItemContainer[i].boardTallyRecordMachineRunArray != null) {
        this.stockItemContainer[i].boardTallyRecordMachineRunArray.forEach(async mr => {

          //boardTallyRecordMachineRun = new BoardTallyRecordMachineRun;
          //boardTallyRecordMachineRun.guMachineRunId = this.stockItemContainer[i].stockItem.guMachineRunId;
          //boardTallyRecordMachineRun.guBoardTallyRecordId = boardTallyRecord.rowguid;

          // No longer need this temp property at this point so delete it.
          if (mr["blnMachineRunAddedIndex"] != null) {
            delete mr["blnMachineRunAddedIndex"];
          }

          await this.boardTallyRecordMachineRunService.createBoardTallyRecordMachineRunPromise(mr);

        });

      }

      if (this.stockItemContainer[i].boardTallyRecordOrderItemArray != null) {
        this.stockItemContainer[i].boardTallyRecordOrderItemArray.forEach(async dtoBTOI => {
          let btoi: BoardTallyRecordOrderItem = new BoardTallyRecordOrderItem();
          btoi.fltOrderItemQuantity = parseFloat(dtoBTOI.fltBTOrderItemQuantity.toString());
          btoi.intOrderItemQuantityUoM = dtoBTOI.intBTOrderItemQuantityUoM;
          btoi.guOrderItemId = dtoBTOI.guOrderItemId;
          btoi.guBoardTallyRecordId = dtoBTOI.guBoardTallyRecordId;
          btoi.dteDateCreated = this.sharedService.currentDatePlusTZOffset();

          console.log(btoi);
          console.log(JSON.stringify(btoi));

          await this.boardTallyRecordOrderItemService.createBoardTallyRecordOrderItemPromise(btoi);

        });

      }

      let newStockItem: dtoStockItem = await this.boardTallyRecordService.getdtoStockItemPromise(boardTallyRecord.rowguid);

    }

    this.printPack(stockContainer.rowguid);

    console.log("in createPackAndPrint: ");


    this.clearPack();

  }

  printPack(stockContainerId: string) {
    let path: string = this.sharedService.LocalServerURI;
    if (location.hostname.indexOf('localhost') == -1) {
      path = 'https://' + location.hostname
    }

    var windowUrl = path + '/order-item-barcode-sticker?stockcontainerid=' + stockContainerId + '&hidenav=yes';
    var uniqueName = new Date();
    var windowName = "_blank";
    var printWindow = window.open(windowUrl, windowName, 'left=400,top=200,width=950,height=600');

  }


  clearPack() {

    for (let i = 0; i <= this.stockItemContainer.length - 1; i++) {
      this.orderItems.data.forEach(oi => {
        oi.fltQtyAdded = 0;
      });
    }

    this.stockItemContainer.length = 0;
    this.stockItems.length = 0;
    this.orderItemBarcodeSticker.stockItems.length = 0;
    this.orderItemBarcodeSticker.datasource.data.length = 0;
    this.orderItemBarcodeSticker.datasource = new MatTableDataSource<dtoStockItem>();

    this.appOrderItemList.resetAddToPackButton("");

  }

  viewAllOrders(customerId: string[]) {

    console.log(customerId);
    this.getActiveAndPartialCompleteOrders(customerId);

  }

}
