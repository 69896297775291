import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileAttachment } from '../../_shared/app-objects/file-attachment';

@Component({
  selector: 'app-photo-viewer-zoom',
  templateUrl: './photo-viewer-zoom.component.html',
  styleUrl: './photo-viewer-zoom.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class PhotoViewerZoomComponent {

  fileAttachment: FileAttachment
  constructor(private dialogRef: MatDialogRef<PhotoViewerZoomComponent>, @Inject(MAT_DIALOG_DATA) private data: { fileAttachment: FileAttachment }) {

    this.fileAttachment = data.fileAttachment;

    console.log("fileAttachment: ", data.fileAttachment);

  }

  close() {
    this.dialogRef.close();

  }

}
