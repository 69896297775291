import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { dtoWebPublishStockSummary } from '../business-objects/dto-web-publish-stock-summary.bo';

@Injectable()
export class dtoWebPublishStockSummaryService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getdtoWebPublishStockSummary(dtoWebPublishStockSummaryId: string) {
		return this.http.get<dtoWebPublishStockSummary>(this._baseUrl + 'api/dtoWebPublishStockSummary/' + dtoWebPublishStockSummaryId);
	}

	getdtoWebPublishStockSummaryParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<dtoWebPublishStockSummary[]>(this._baseUrl + 'api/dtoWebPublishStockSummary/GetdtoWebPublishStockSummaryParamArray/', aParam);
	}

	getAlldtoWebPublishStockSummarys() {
		return this.http.get<dtoWebPublishStockSummary[]>(this._baseUrl + 'api/dtoWebPublishStockSummary/GetAlldtoWebPublishStockSummary/');
	}

	//getAlldtodtoWebPublishStockSummarys() {
	//	return this.http.get<dtodtoWebPublishStockSummary[]>(this._baseUrl + 'api/dtoWebPublishStockSummary/GetAlldtoWebPublishStockSummary/');
	//}

	createdtoWebPublishStockSummary(dtoWebPublishStockSummary: dtoWebPublishStockSummary) {
		return this.http.post<dtoWebPublishStockSummary>(this._baseUrl + 'api/dtoWebPublishStockSummary/CreatedtoWebPublishStockSummary/', dtoWebPublishStockSummary);
	}

	updatedtoWebPublishStockSummary(dtoWebPublishStockSummary: dtoWebPublishStockSummary) {
		return this.http.put<dtoWebPublishStockSummary>(this._baseUrl + 'api/dtoWebPublishStockSummary/UpdatedtoWebPublishStockSummary/', dtoWebPublishStockSummary);
	}

	deletedtoWebPublishStockSummary(dtoWebPublishStockSummaryId: string) {
		return this.http.delete(this._baseUrl + 'api/dtoWebPublishStockSummary/DeletedtoWebPublishStockSummary/' + dtoWebPublishStockSummaryId);
	}

	async getdtoWebPublishStockSummaryPromise(dtoWebPublishStockSummaryId: string) {
		return new Promise<dtoWebPublishStockSummary>(resolve => {
			this.getdtoWebPublishStockSummary(dtoWebPublishStockSummaryId).subscribe(dtoWebPublishStockSummary => {
				resolve(dtoWebPublishStockSummary);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoWebPublishStockSummary records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async getdtoWebPublishStockSummaryParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<dtoWebPublishStockSummary[]>(resolve => {
			this.getdtoWebPublishStockSummaryParamArray(aParam).subscribe(dtoWebPublishStockSummary => {
				resolve(dtoWebPublishStockSummary);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoWebPublishStockSummary records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async updatedtoWebPublishStockSummaryPromise(dtoWebPublishStockSummary: dtoWebPublishStockSummary) {
		return new Promise<dtoWebPublishStockSummary>(resolve => {
			this.updatedtoWebPublishStockSummary(dtoWebPublishStockSummary).subscribe(dtoWebPublishStockSummary => {
				resolve(dtoWebPublishStockSummary);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating dtoWebPublishStockSummary record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createdtoWebPublishStockSummaryPromise(dtoWebPublishStockSummary: dtoWebPublishStockSummary) {
		return new Promise<dtoWebPublishStockSummary>(resolve => {
			this.createdtoWebPublishStockSummary(dtoWebPublishStockSummary).subscribe(dtoWebPublishStockSummary => {
				resolve(dtoWebPublishStockSummary);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating dtoWebPublishStockSummary record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async deletedtoWebPublishStockSummaryPromise(dtoWebPublishStockSummaryId: string) {
		return new Promise<boolean>(resolve => {
			this.deletedtoWebPublishStockSummary(dtoWebPublishStockSummaryId).subscribe(dtoWebPublishStockSummary => {
				resolve(true);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error removing dtoWebPublishStockSummary record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(false);
			});
		});
	}

}