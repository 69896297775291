import { Component, Input, OnInit } from '@angular/core';
import { FileAttachment } from '../../_shared/app-objects/file-attachment';
import { dtoStockItem } from '../../_shared/business-objects/dto-stock-item.bo';
import { FileService } from '../../_shared/services/app-services/file.service';
import { GuestService } from '../../_shared/services/guest.service';

@Component({
  selector: 'app-product-tile',
  templateUrl: './product-tile.component.html',
  styleUrl: './product-tile.component.scss'
})
export class ProductTileComponent implements OnInit {

  @Input() stockItem: dtoStockItem;

  dataURL: string;

  constructor(private fileService: FileService, private guestService: GuestService) {

  }


  ngOnInit() {

    //console.log(this.stockItem);

    //file:////mtechstor.file.core.windows.net//chhfiles//mtech//BoardPlant//StockItemThumbnailPhotos//924b43b7-451a-44e7-b0ab-50d120c7ed49//1730949166029527796257317960735.jpg
    this.getThumbnail();

  }

  async getThumbnail() {

    let attachments: FileAttachment[] = await this.guestService.getStockThumbnailImages(this.stockItem.rowguid);

    if (attachments != null && attachments.length > 0) {
      this.dataURL = attachments[0].dataURI();
    }

    return this.dataURL;
  }


  formatDollars(val: number) {
    if (val == null || val == 0) {
      return "Price TBA";
    }

    let dollars: number = Number(val.toString().substring(0, val.toString().indexOf('.')));
    if (dollars == 0) {
      return "$0";
    }

    return "$" + dollars.toString();
  }


  formatCents(val: number) {
    if (val == null || val == 0) {
      return "";
    }

    let cents: number = val != null ? Number(val.toString().substring(val.toString().indexOf('.') + 1, val.toString().length)) : 0;
    if (cents == 0) {
      return ".00";
    }

    if (cents.toString().length == 1) {
      return "." + cents.toString() + "0";
    }

    return "." + cents.toString();
  }

  formatPriceType(val: string, price: number) {

    if (val == null || price == null || price == 0) {
      return "";
    }

    let priceType: string = val != null ? val.toString().substring(val.toString().indexOf('/') + 1, val.toString().length) : "";
    if (priceType.toLowerCase() == "lm") {
      priceType = "per 1 lineal metre";
    }

    if (priceType.toLowerCase() == "m2") {
      priceType = "per 1 square metre";
    }

    if (priceType.toLowerCase() == "m3") {
      priceType = "per 1 cubic metre";
    }

    if (priceType.toLowerCase() == "ton") {
      priceType = "per 1 tonne";
    }


    //console.log("priceType: " + priceType);

    return priceType;
  }


}
