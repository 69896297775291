<div class="product-tile-container" *ngIf="stockItem != null">
  <div class="product-tile-grid">
    <div class="photo-thumb-nail-image">
      <mat-icon matTooltip="" class="product-image material-symbols-outlined" *ngIf="dataURL == null">image</mat-icon>
      <img *ngIf="dataURL != null" [src]="dataURL" width="100" alt="Image" />
    </div>
    <div class="description-container">
      <div class="product-tile-description product-name">{{stockItem.txtProductName}}</div>
      <div class="product-tile-description profile-name">{{stockItem.txtProfileName}}</div>
      <div class="product-tile-description width">{{stockItem.intWidth}}</div>
      <div class="product-tile-description width-thickness-divider" *ngIf="stockItem.intWidth != null && stockItem.intThickness != null">x</div>
      <div class="product-tile-description thickness">{{stockItem.intThickness}}mm</div>
      <div class="product-tile-description species" [innerHTML]="stockItem.txtSpeciesName | camelCase"></div>
      <div class="product-tile-description grade" [innerHTML]="stockItem.txtGradeName.toLowerCase() == 'none' ? '' : (stockItem.txtGradeName + ' Grade' | camelCase)"></div>
    </div>
    <div class="more-options">more options</div>
    <div class="price-qty-container-grid">
      <div class="pricing-container">
        <div class="price-dollars">
          {{formatDollars(stockItem.fltApprovedPrice)}}
        </div>
        <div class="price-cents">
          {{formatCents(stockItem.fltApprovedPrice)}}
        </div>
        <div class="price-type">
          {{formatPriceType(stockItem.txtApprovedPriceText, stockItem.fltApprovedPrice)}}

        </div>
      </div>
      <div class="quantity" *ngIf="false">{{stockItem.fltQuantity + (stockItem.txtQuantityUoM != null && stockItem.txtQuantityUoM.trim() == 'Each' ? ' @ ' + stockItem.intTotalLM : '')}}</div>
      <div class="quantity-uom" *ngIf="false">{{stockItem.txtQuantityUoM != null && stockItem.txtQuantityUoM.trim() != 'Each' ? stockItem.txtQuantityUoM : ''}}</div>
    </div>
  </div>
</div>
