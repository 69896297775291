import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { FileAttachment } from "../app-objects/file-attachment";
import { BoardTallyRecordPhoto } from "../business-objects/board-tally-record-photo.bo";
import { dtoKeyValue, dtoStrKeyValue } from "../business-objects/dto-int-key-value.bo";
import { dtoProductTypeCategoryGrouped } from "../business-objects/dto-product-type-category-grouped.bo";
import { dtoStockItemQuantitySummary } from "../business-objects/dto-stock-item-quantity-summary.bo";
import { dtoStockItem } from "../business-objects/dto-stock-item.bo";
import { dtoWebPublishStockSummary } from "../business-objects/dto-web-publish-stock-summary.bo";
import { SQLParamArray } from "../business-objects/sql-param-array";
import { FileService } from "./app-services/file.service";
import { SharedService } from "./shared.service";


@Injectable()
export class GuestService {

  public _baseUrl: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private sharedService: SharedService, private fileService: FileService) {
    this._baseUrl = baseUrl;
  }

  getdtoStockItemQuantitySummary(identifier: number) {
    return this.http.get<dtoStockItemQuantitySummary[]>(this._baseUrl + 'api/guest/GetdtoStockItemQuantitySummary/' + identifier);
  }

  getSlobStockParamArray(aParam: SQLParamArray[][]) {
    return this.http.post<dtoStockItem[]>(this._baseUrl + 'api/guest/GetSlobStock/', aParam);
  }

  getdtoProductTypeCategoryGroupedParamArray(aParam: SQLParamArray[][]) {
    return this.http.post<dtoProductTypeCategoryGrouped[]>(this._baseUrl + 'api/guest/GetdtoProductTypeCategoryGroupedsParamArray/', aParam);
  }

  getWebPublishStockSummary(aParam: SQLParamArray[][]) {
    return this.http.post<dtoWebPublishStockSummary[]>(this._baseUrl + 'api/guest/GetWebPublishStockSummary/', aParam);
  }

  getWebPublishStockDetail(aParam: SQLParamArray[][]) {
    return this.http.post<dtoStockItem[]>(this._baseUrl + 'api/guest/GetWebPublishStockDetail/', aParam);
  }

  getBoardTallyRecordPhotoParamArray(aParam: SQLParamArray[][]) {
    return this.http.post<BoardTallyRecordPhoto[]>(this._baseUrl + 'api/guest/GetBoardTallyRecordPhotosParamArray/', aParam);
  }

  async getSlobStockParamArrayPromise() {
    return new Promise<dtoStockItem[]>(resolve => {
      let aParamList: SQLParamArray[][] = [];
      let aParam: SQLParamArray[] = [];
      aParam.push(new SQLParamArray("blnSlobStock", "1"));
      aParamList.push(aParam);

      aParam = [];
      aParam.push(new SQLParamArray("blnStockContainerArchived", "0"));
      aParamList.push(aParam);

      aParam = [];
      aParam.push(new SQLParamArray("blnArchive", "0"));
      aParamList.push(aParam);

      aParam = [];
      aParam.push(new SQLParamArray("txtCustomerName", "", SQLParamArray.enSQLOperator.IsNull));
      aParamList.push(aParam);

      this.getSlobStockParamArray(aParamList).subscribe(boardTallyRecords => {
        resolve(boardTallyRecords);
      }, err => {
        console.log(err);
        resolve(null);
      });

    });
  }

  async getWebPublishStockDetailPromise(webPublishStockSummary: dtoWebPublishStockSummary) {
    return new Promise<dtoStockItem[]>(resolve => {
      let aParamList: SQLParamArray[][] = [];
      let aParam: SQLParamArray[] = [];
      aParam.push(new SQLParamArray("intWebPublishStatus", "2"));
      aParamList.push(aParam);

      if (webPublishStockSummary.intWidth !== null) {
        aParam = [];
        aParam.push(new SQLParamArray("intWidth", String(webPublishStockSummary.intWidth).toString()));
        aParamList.push(aParam);
      }

      if (webPublishStockSummary.intThickness !== null) {
        aParam = [];
        aParam.push(new SQLParamArray("intThickness", String(webPublishStockSummary.intThickness).toString()));
        aParamList.push(aParam);
      }

      if (webPublishStockSummary.txtProductName !== null) {
        aParam = [];
        aParam.push(new SQLParamArray("txtProductName", String(webPublishStockSummary.txtProductName).toString()));
        aParamList.push(aParam);
      }

      if (webPublishStockSummary.txtProfileName !== null) {
        aParam = [];
        aParam.push(new SQLParamArray("txtProfileName", String(webPublishStockSummary.txtProfileName).toString()));
        aParamList.push(aParam);
      }

      if (webPublishStockSummary.txtSpeciesName !== null) {
        aParam = [];
        aParam.push(new SQLParamArray("txtSpeciesName", String(webPublishStockSummary.txtSpeciesName).toString()));
        aParamList.push(aParam);
      }

      if (webPublishStockSummary.txtGradeName !== null) {
        aParam = [];
        aParam.push(new SQLParamArray("txtGradeName", String(webPublishStockSummary.txtGradeName).toString()));
        aParamList.push(aParam);
      }

      this.getWebPublishStockDetail(aParamList).subscribe(boardTallyRecords => {
        resolve(boardTallyRecords);
      }, err => {
        console.log(err);
        resolve(null);
      });

    });
  }

  async getBoardTallyRecordPhotoParamArrayPromise(aParam: SQLParamArray[][]) {
    return new Promise<BoardTallyRecordPhoto[]>(resolve => {
      this.getBoardTallyRecordPhotoParamArray(aParam).subscribe(boardTallyRecordPhotos => {
        resolve(boardTallyRecordPhotos);
      }, err => {
        //this.alertService.openSnackBarError("Error getting BoardTallyRecordPhoto records", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }

  async getdtoStockItemQuantitySummaryPromise(identifier: number) {
    return new Promise<dtoStockItemQuantitySummary[]>(resolve => {
      this.getdtoStockItemQuantitySummary(identifier).subscribe(dtoStockItemQuantitySummary => {
        resolve(dtoStockItemQuantitySummary);
      }, err => {
        //this.alertService.openSnackBarError("Error getting dtoStockItemQuantitySummary records", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }

  async getSlobStockSummaryPromise(category: string, product: string, profile: string, width: number, thickness: number, species: string, visGrade: string, structGrade: string) {
    return new Promise<dtoWebPublishStockSummary[]>(resolve => {
      let aParamList: SQLParamArray[][] = [];
      let aParam: SQLParamArray[] = [];
      aParam.push(new SQLParamArray("iSlobStock", "0", SQLParamArray.enSQLOperator.GreaterThan));
      aParamList.push(aParam);

      if (category != null && category != "") {
        aParam.push(new SQLParamArray("", ""));
        aParamList.push(aParam);
      }

      if (product != null && product != "") {
        aParam = [];
        aParam.push(new SQLParamArray("txtProductName", product));
        aParamList.push(aParam);
      }

      if (profile != null && profile != "") {
        aParam = [];
        aParam.push(new SQLParamArray("txtProfileName", profile));
        aParamList.push(aParam);
      }

      if (width != null && width > 0) {
        aParam = [];
        aParam.push(new SQLParamArray("intWidth", width.toString()));
        aParamList.push(aParam);
      }

      if (thickness != null && thickness > 0) {
        aParam = [];
        aParam.push(new SQLParamArray("intThickness", thickness.toString()));
        aParamList.push(aParam);
      }

      if (species != null && species != "") {
        aParam = [];
        aParam.push(new SQLParamArray("txtSpeciesName", species));
        aParamList.push(aParam);
      }

      if (visGrade != null && visGrade != "") {
        aParam = [];
        aParam.push(new SQLParamArray("txtGradeName", visGrade));
        aParamList.push(aParam);
      }

      if (structGrade != null && structGrade != "") {
        aParam = [];
        aParam.push(new SQLParamArray("", ""));
        aParamList.push(aParam);
      }

      this.getWebPublishStockSummary(aParamList).subscribe(slobStockSummary => {
        resolve(slobStockSummary);
      }, err => {
        console.log(err);
        resolve(null);
      });

    });
  }

  async getdtoProductTypeCategoryGroupedParamArrayPromise(aParam: SQLParamArray[][]) {
    return new Promise<dtoProductTypeCategoryGrouped[]>(resolve => {
      this.getdtoProductTypeCategoryGroupedParamArray(aParam).subscribe(dtoProductTypeCategoryGroupeds => {
        resolve(dtoProductTypeCategoryGroupeds);
      }, err => {
        //this.alertService.openSnackBarError("Error getting dtoProductTypeCategoryGrouped records", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }

  async getStockThumbnailImages(stockId: string): Promise<FileAttachment[]> {

    return new Promise<FileAttachment[]>(resolve => {
      let fileAttachments: FileAttachment[] = [];
      let fileAttachment: FileAttachment;
      //let keyValue: dtoKeyValue = new dtoKeyValue()
      //keyValue.txtValue = path;

      this.http.get<FileAttachment[]>(this._baseUrl + 'api/Guest/GetStockThumbnailImages/' + stockId).subscribe((data: FileAttachment[]) => {

        if (data != null) {
          data.forEach(async f => {
            fileAttachment = new FileAttachment(f.fileName, f.filePath, f.contentType, f.bytes, f.byteArr, f.size, f.fileDate);
            fileAttachments.push(fileAttachment);

          });

          resolve(fileAttachments);
        }

        resolve(null);

      });;

    });

  }
  //GetStockThumbnailImage

  async getFiles(paths: string[]): Promise<FileAttachment[]> {

    return new Promise<FileAttachment[]>(resolve => {
      if (paths == null || paths.length == 0) {
        resolve(null);
        return;
      }

      let fileAttachments: FileAttachment[] = [];
      let fileAttachment: FileAttachment;

      this.http.post<FileAttachment[]>(this._baseUrl + 'api/guest/GetFiles', paths).subscribe((data: FileAttachment[]) => {

        data.forEach(async f => {
          fileAttachment = new FileAttachment(f.fileName, f.filePath, f.contentType, f.bytes, f.byteArr, f.size, f.fileDate);
          fileAttachments.push(fileAttachment);

        });

        resolve(fileAttachments);
      });;

    });

  }


  async getFile(path: string, fileName: string): Promise<FileAttachment[]> {

    return new Promise<FileAttachment[]>(resolve => {
      if ((path == null || path == "") && (fileName == null || fileName == "")) {
        resolve(null);
        return;
      }

      let fileAttachments: FileAttachment[] = [];
      let fileAttachment: FileAttachment;
      let keyValue: dtoKeyValue = new dtoKeyValue();
      keyValue.txtValue = path;
      keyValue.txtValue2 = fileName;

      this.http.post<FileAttachment[]>(this._baseUrl + 'api/guest/GetFile', keyValue).subscribe((data: FileAttachment[]) => {

        data.forEach(async f => {
          fileAttachment = new FileAttachment(f.fileName, f.filePath, f.contentType, f.bytes, f.byteArr, f.size, f.fileDate);
          fileAttachments.push(fileAttachment);

        });

        resolve(fileAttachments);
      });;

    });

  }

}  
