<div class="stock-for-sale-container">
  <div class="header" id="stockForSaleHeader">
    <div class="header-grid">
      <div class="header-left">
        <a class="navbar-brand" href="https://coffshardwoods.com.au/" title="Coffs Harbour Hardwoods" rel="home">
          <img id="logomain" class="logo" src="https://coffshardwoods.com.au/wp-content/themes/Coffs%20Harbour%20Hardwoods/images/logowhite.png" title="" alt="Coffs Harbour Hardwoods">
        </a>
      </div>
      <div class="header-right">
          <h1>Stock For Sale</h1>
      </div>
    </div>
  </div>

  <div class="filter-container" *ngIf="selectedStockSummary == null">
    <app-product-type-category-select [productTypeCategories]="productTypeCategories" (selectedProductTypeCategoriesEvent)="selectedProductTypeCategories($event)"></app-product-type-category-select>
  
  </div>

  <div class="stock-item-list-container" *ngIf="selectedStockSummary == null">
    <div class="stock-item-grid">
      @for(stockItem of stockItems; track stockItem; let idx = $index){
      <div class="product-tile-column">
          <app-product-tile id="tile{{idx}}" [tileIndex]="idx" [webPublishStockSummaryItem]="stockItem" (viewProductDetailEvent)="viewProductDetail($event)"></app-product-tile>
      </div>
      }
    </div>

  </div>

  <div class="view-detail-container" *ngIf="selectedStockSummary != null">
    <app-product-detail-view [selectedStockSummary]="selectedStockSummary" [selectedTileId]="selectedTileId" (closeProductDetailEvent)="closeProductDetail($event)"></app-product-detail-view>
  </div>
</div>
