export interface BoardTallyRecordPhoto
{
	blnActive: boolean;
	blnThumbnail: boolean;
	dteDateCreated: Date;
	dteLastUpdated: Date;
	guBoardTallyRecordId: string;
	intOrder: number;
	rowguid: string;
	txtComments: string;
	txtFileName: string;
}

export class BoardTallyRecordPhoto
{
	blnActive: boolean;
	blnThumbnail: boolean;
	dteDateCreated: Date;
	dteLastUpdated: Date;
	guBoardTallyRecordId: string;
	intOrder: number;
	rowguid: string;
	txtComments: string;
	txtFileName: string;
}

