import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { getType } from 'pdf-lib';
import { AuthService } from '../account/auth.service';
import { BoardTallyRecordHistory } from '../_shared/business-objects/board-tally-record-history.bo';
import { BoardTallyRecordMachineRun } from '../_shared/business-objects/board-tally-record-machine-run.bo';
import { BoardTallyRecordMoulder } from '../_shared/business-objects/board-tally-record-moulder.bo';
import { BoardTallyRecordProductProfile } from '../_shared/business-objects/board-tally-record-product-profile.bo';
import { BoardTallyRecord } from '../_shared/business-objects/board-tally-record.bo';
import { dtoProductCode } from '../_shared/business-objects/dto-product-code.bo';
import { dtoProductCodes } from '../_shared/business-objects/dto-product-codes.bo';
import { dtoStockItemDisplay } from '../_shared/business-objects/dto-stock-item-display.bo';
import { dtoStockItem } from '../_shared/business-objects/dto-stock-item.bo';
import { GeoStockLocation } from '../_shared/business-objects/geo-stock-location.bo';
import { Moulder } from '../_shared/business-objects/moulder.bo';
import { ProductCode } from '../_shared/business-objects/product-code.bo';
import { DBFieldFunction, SQLParamArray } from '../_shared/business-objects/sql-param-array';
import { StockContainerType } from '../_shared/business-objects/stock-container-type.bo';
import { StockContainer } from '../_shared/business-objects/stock-container.bo';
import { AlertService } from '../_shared/services/alert.service';
import { BoardTallyRecordMachineRunService } from '../_shared/services/board-tally-record-machine-run.service';
import { BoardTallyRecordMoulderService } from '../_shared/services/board-tally-record-moulder.service';
import { BoardTallyRecordProductProfileService } from '../_shared/services/board-tally-record-product-profile.service';
import { BoardTallyRecordService } from '../_shared/services/board-tally-record.service';
import { dtoStockItemService } from '../_shared/services/dto-stock-item.service';
import { GeoStockLocationService } from '../_shared/services/geo-stock-location.service';
import { MoulderService } from '../_shared/services/moulder.service';
import { ProductCodeService } from '../_shared/services/product-code.service';
import { SharedService } from '../_shared/services/shared.service';
import { StockContainerTypeService } from '../_shared/services/stock-container-type.service';
import { StockContainerService } from '../_shared/services/stock-container.service';
import { UserPositionService } from '../_shared/services/user-position.service';

@Component({
  selector: 'app-stock-item-create-by-product-code',
  templateUrl: './stock-item-create-by-product-code.component.html',
  styleUrls: ['./stock-item-create-by-product-code.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class StockItemCreateByProductCodeComponent implements OnInit {

  form: FormGroup;

  @ViewChild('quantityUOM') quantityUOM: ElementRef;

  @Output() stockCreated = new EventEmitter<dtoStockItem>();
  @Input() mode: string;
  @Input() boardTallyRecordHistory: dtoStockItem;

  geoStockLocations: GeoStockLocation[];
  myGeoStockLocations: GeoStockLocation[];
  defaultGeoLocation: GeoStockLocation;

  geoLocationDisabled: boolean = true;

  selectedMoulderValue: string;
  moulders: Moulder[];
  selectedProductCode: dtoProductCode;

  stockItems: dtoStockItem[] = [];
  productCodes: dtoProductCodes[];

  showFromLocations: boolean = false;
  showSpinner: boolean = false;

  timer;
  keyPressTime: number;

  bpFloor: boolean = false;
  officeAdmin: boolean = false;
  salesStaff: boolean = false;
  coffsSalesStaff: boolean = false;


  constructor(private fb: FormBuilder, private geoStockLocationService: GeoStockLocationService, private moulderService: MoulderService, private authService: AuthService
              , private boardTallyRecordService: BoardTallyRecordService, private productCodeService: ProductCodeService, private alertService: AlertService
    , private stockContanerService: StockContainerService, private stockContanerTypeService: StockContainerTypeService, private sharedService: SharedService
    , private boardTallyRecordMachineRunService: BoardTallyRecordMachineRunService, private boardTallyRecordMoulderService: BoardTallyRecordMoulderService
    , private boardTallyRecordProductProfileService: BoardTallyRecordProductProfileService, private userPositionService: UserPositionService  ) {

    this.form = this.fb.group({
      guGeoStockLocationId: [''],
      txtIdentifier: [''],
      txtLocation: [''],
      guMoulderId: [''],
      txtSKU: [''],
      fltQuantity: [''],
      txtSearchSKU: ['']
    });

  }

  ngOnInit(): void {

    this.loadPositions();

    this.loadData();

    
    //let aParamList: SQLParamArray[][] = [];
    //let aParam: SQLParamArray[] = [];
    //aParam.push(new SQLParamArray("txtContainerLocation", "P-0-16"))
    //aParamList.push(aParam);

    //this.boardTallyRecordService.getNonDeliveredStockItemParamArray(aParamList).subscribe(stockItems => {

    //  this.stockItems = stockItems;

    //}, err => {
    //  console.log(err);
    //});

    this.keyPressTime = Date.now();

    console.log("Mode: " + this.mode);

  }

  async loadPositions() {

    this.bpFloor = await this.userPositionService.isInPosition("board plant floor", this.authService.getAppUserEmployeeId());
    this.salesStaff = await this.userPositionService.isInPosition("sales staff", this.authService.getAppUserEmployeeId());
    this.coffsSalesStaff = await this.userPositionService.isInPosition("coffs sales staff", this.authService.getAppUserEmployeeId());
    this.officeAdmin = await this.userPositionService.isInPosition("office admin", this.authService.getAppUserEmployeeId());

    if (this.salesStaff || this.officeAdmin || this.coffsSalesStaff == true) {
      this.geoLocationDisabled = false;
    }

  }

  async loadData() {


    let aParamList: SQLParamArray[][] = [];
    //let aParam: SQLParamArray[] = [];
    //aParam.push(new SQLParamArray("guProductCodeId", productCodeId))
    //aParamList.push(aParam);

    this.moulderService.getMoulderParamArray(aParamList).subscribe(moulders => {
      this.moulders = moulders;

    }, err => {
      console.log(err);
    });

    this.defaultGeoLocation = await this.getMyDefaultGeoLocation();
    this.form.controls['guGeoStockLocationId'].setValue(this.defaultGeoLocation.rowguid);

    this.myGeoStockLocations = await this.getMyGeoStockLocations();

    await new Promise<GeoStockLocation[]>(resolve => {
      this.geoStockLocationService.getAllGeoStockLocations().subscribe(geoLocations => {

        this.geoStockLocations = geoLocations;

        resolve(geoLocations);

      }, err => {
        console.log(err);
        resolve(null);
      });
    });

    if (this.mode == "additem") {
      this.form.controls['txtIdentifier'].setValue(this.boardTallyRecordHistory.intIdentifier);
      this.form.controls['guGeoStockLocationId'].setValue(this.boardTallyRecordHistory.guGeoStockLocationId);
      this.form.controls['txtLocation'].setValue(this.boardTallyRecordHistory.txtContainerLocation);
      this.form.controls['guMoulderId'].setValue(this.boardTallyRecordHistory.guMoulderId);
    }

    //setTimeout(this.setAutoFocus, 1000, "txtIdentifier");

  }

  async getMyGeoStockLocations() {
    return new Promise<GeoStockLocation[]>(resolve => {
      this.geoStockLocationService.getMyGeoStockLocations(this.authService.getAppUserId()).subscribe(myLocations => {
        this.showFromLocations = true;
        resolve(myLocations);

      }, err => {
        console.log(err);
        resolve(null);
      });
    });
  }

  async getMyDefaultGeoLocation() {

    return new Promise<GeoStockLocation>(resolve => {
      this.geoStockLocationService.getMyDefaultGeoStockLocation(this.authService.getAppUserId()).subscribe(stockLocation => {

        resolve(stockLocation);

      }, err => {
        console.log(err);
      });
    });


  }

  showMyLocations(val) {
    //console.log("myGeoStockLocations: " + this.myGeoStockLocations + "  :   defaultGeoLocation- " + this.defaultGeoLocation);

    if (this.myGeoStockLocations == null || this.defaultGeoLocation == null) {
      return true;
    }

    for (let i = 0; i <= this.myGeoStockLocations.length - 1; i++) {
      console.log("this.myGeoStockLocations[i].rowguid: " + this.myGeoStockLocations[i].rowguid + "  :   this.defaultGeoLocation.rowguid- " + this.defaultGeoLocation.rowguid);

      if (this.defaultGeoLocation.rowguid == this.myGeoStockLocations[i].rowguid) {
        return false;
      }
    }
    return true;
  }



  async createStock(useAutoNumbers: boolean) {

    let existingStockItem: dtoStockItem;

    if (useAutoNumbers == false) {
      if (this.form.controls['txtIdentifier'].value.toString() == "") {
        this.alertService.openSnackBar("Please enter an identifer.", "Close", "center", "bottom", "", 3000);
        return;
      }

      if (isNaN(this.form.controls['txtIdentifier'].value.toString())) {
        this.alertService.openSnackBar("The identifer is not a number.", "Close", "center", "bottom", "", 3000);
        return;
      }

      let aParamList: SQLParamArray[][] = [];
      let aParam: SQLParamArray[] = [];
      aParam.push(new SQLParamArray("intIdentifier", this.form.controls['txtIdentifier'].value.toString()))
      aParamList.push(aParam);

      let boardTallyRecordHistory: dtoStockItem[] = await this.boardTallyRecordService.getStockItemParamArrayPromise(aParamList);
      if (boardTallyRecordHistory != null && boardTallyRecordHistory.length > 0) {

        existingStockItem = boardTallyRecordHistory[0];
        if (this.mode == "add") {
          this.alertService.openSnackBar("The identifer already already exists.", "Close", "center", "bottom", "", 3000);
          return;
        }
      }
    }

    if (this.form.controls['fltQuantity'].value.toString() == "") {
      this.alertService.openSnackBar("Please enter a quantity.", "Close", "center", "bottom", "", 3000);
      return;
    }


    if (this.selectedMoulderValue == null || this.selectedMoulderValue == "") {
      this.alertService.openSnackBar("Please select a moulder.", "Close", "center", "bottom", "", 3000);
      return;
    }


    if (this.selectedProductCode == null) {
      this.alertService.openSnackBar("Please enter a SKU.", "Close", "center", "bottom", "", 3000);
      return;
    }

    let stockContainer: StockContainer = new StockContainer;
    let boardTallyRecord: BoardTallyRecord = new BoardTallyRecord;
    let boardTallyRecordMachineRun: BoardTallyRecordMachineRun;
    let boardTallyRecordMoulder: BoardTallyRecordMoulder;
    let boardTallyRecordProfile: BoardTallyRecordProductProfile;
    let stockContainerType: StockContainerType = await this.stockContanerTypeService.getStockContainerByNamePromise("Pack");


    //===================================================================================================================
    // TEST USING ADD ITEM MODE
    //===================================================================================================================

    if (this.mode == "additem") {
      stockContainer = await this.stockContanerService.getStockContainerPromise(existingStockItem.guStockContainerId);
    }


    // We are add mode so add stock container.
    // Otherwise we are adding an item to the stock container.
    if (this.mode == "add") {


      delete stockContainer.rowguid;  //remove rowguid property so DB will create one.
      if (useAutoNumbers == true) {
        stockContainer.intIdentifier = 0;
      }

      if (useAutoNumbers == false) {
        stockContainer.intIdentifier = parseInt(this.form.controls['txtIdentifier'].value);
      }
      stockContainer.guStockContainerTypeId = stockContainerType.rowguid;
      stockContainer.txtLocation = this.form.controls['txtLocation'].value.toString();
      stockContainer.dteCreated = this.sharedService.currentDatePlusTZOffset();
      stockContainer.dteOriginalDateCreated = this.sharedService.currentDatePlusTZOffset();
      stockContainer.dteLastModified = this.sharedService.currentDatePlusTZOffset();
      stockContainer.dteStockTake = null;
      stockContainer.intStatus = StockContainer.enStatus.Active;
      stockContainer.blnArchived = false;
      stockContainer.guGeoStockLocationId = this.form.controls['guGeoStockLocationId'].value.toString();

      let res: StockContainer | HttpErrorResponse;
      res = await this.stockContanerService.createStockContainerPromise(this.authService.getAppUserEmployeeId(), false, BoardTallyRecordHistory.enChangeReason.Created, useAutoNumbers, stockContainer);


      if (res instanceof HttpErrorResponse) {
        stockContainer = null;

        //console.log("res.error: ", res.error);
        //console.log("res.error.indexOf: ", res.error.indexOf("The manual identifier number is higher than"));

        //Something went wrong creating the stock container so abort.
        let msg: string = "There was an error creating the stock container"
        if (res.error.indexOf("The manual identifier number is higher than") > -1) {
          msg += "\nPerhaps the manual pack numer is higher than 149999. Please check your number."
        }
        await this.alertService.openSnackBarCustomDefaultPromise("Error Creating Stock Container", msg, "Ok");
        return;
      }

      console.log("res: ", res);
      console.log("res instanceof StockContainer: ", res instanceof StockContainer);
      stockContainer = res;

    }


    console.log("stockContainer: ", stockContainer);
    if (stockContainer == null) {
      ////Something went wrong creating the stock container so abort.
      //let msg: string = "There was an error creating the stock container"
      //if (useAutoNumbers == true) {
      //  msg += "\nPerhaps the manual numer "
      //}
      //await this.alertService.openSnackBarCustomDefaultPromise("Error Creating Stock Container", "", "Ok");
      return;
    }


    delete boardTallyRecord.rowguid;
    boardTallyRecord.intItemNo = 1;
    boardTallyRecord.guStockContainerId = stockContainer.rowguid;
    boardTallyRecord.dteDateCreated = this.sharedService.currentDatePlusTZOffset();
    boardTallyRecord.dteDate = this.sharedService.currentDatePlusTZOffset();
    boardTallyRecord.fltQuantity = parseFloat(this.form.controls['fltQuantity'].value);
    boardTallyRecord.intQuantityUoM = this.selectedProductCode.intDefaultQtyUoM;
    boardTallyRecord.guProductCodeId = this.selectedProductCode.rowguid;
    boardTallyRecord.guProductProfileId = this.selectedProductCode.guProfileId;
    boardTallyRecord.guProfileId = this.selectedProductCode.guProductTypeId;
    boardTallyRecord.guMoulderId = this.selectedMoulderValue;
    boardTallyRecord.intWidth = this.selectedProductCode.fltWidth;
    boardTallyRecord.intThickness = this.selectedProductCode.fltThickness;
    boardTallyRecord.fltNominalWidth = this.selectedProductCode.fltStartWidth;
    boardTallyRecord.fltNominalThickness = this.selectedProductCode.fltStartThickness;
    boardTallyRecord.fltExWidth = this.selectedProductCode.fltStartWidth;
    boardTallyRecord.fltExThickness = this.selectedProductCode.fltStartThickness;
    boardTallyRecord.guSpeciesId = this.selectedProductCode.guSpeciesId;
    boardTallyRecord.guBoardGradeId = this.selectedProductCode.guGradeId;
    boardTallyRecord.intTotalLM = this.selectedProductCode.fltLength;
    boardTallyRecord.fltDia = this.selectedProductCode.fltDia;
    boardTallyRecord.blnArchive = false;

    boardTallyRecord = await this.boardTallyRecordService.createBoardTallyRecordPromise(this.authService.getAppUserEmployeeId(), true, BoardTallyRecordHistory.enChangeReason.Created, boardTallyRecord);


    boardTallyRecordMoulder = new BoardTallyRecordMoulder;
    boardTallyRecordMoulder.guMoulderId = this.selectedMoulderValue;
    boardTallyRecordMoulder.guBoardTallyRecordId = boardTallyRecord.rowguid;
    boardTallyRecordMoulder.intNoOfRuns = 1;
    //boardTallyRecordMoulder.dteLastUpdated = null;

    await this.boardTallyRecordMoulderService.createBoardTallyRecordMoulderPromise(boardTallyRecordMoulder);

    // =============================================================================
    // NEED TO CREATE BOARD TALLY RECORD PRODUCT PROFILE 
    // =============================================================================

    boardTallyRecordProfile = new BoardTallyRecordProductProfile;
    boardTallyRecordProfile.guProductProfileId = this.selectedProductCode.guProfileId;
    boardTallyRecordProfile.guBoardTallyRecordId = boardTallyRecord.rowguid;
    await this.boardTallyRecordProductProfileService.createBoardTallyRecordProductProfilePromise(boardTallyRecordProfile);



    let newStockItem: dtoStockItem = await this.boardTallyRecordService.getdtoStockItemPromise(boardTallyRecord.rowguid);


    this.stockCreated.emit(newStockItem);

  }


  disablingIdentifier(chkUseAutoNumbers, txtIdentifier) {
    if (chkUseAutoNumbers == null || txtIdentifier == null) {
      return;
    }

    if (chkUseAutoNumbers.checked == true) {
      txtIdentifier.value = "";
    }

    if (this.mode == 'add') {
      return true
    }

    return false
  }

  getProductCodes(sku: string) {

    this.productCodes = null;
    this.selectedProductCode = null;

    if (sku.length < 2) {
      return;
    }



    let seconds = (Date.now() - this.keyPressTime);
    if (seconds < 1000) {
      clearTimeout(this.timer);
    }

    this.timer = setTimeout(this.loadProductCodes, 1000, sku, this);

    this.keyPressTime = Date.now();

  }


  loadProductCodes(sku: string, oThis) {

    let aParamList: SQLParamArray[][] = [];
    let aParam: SQLParamArray[] = [];
    aParam.push(new SQLParamArray("txtStockKeepingUnit", sku, SQLParamArray.enSQLOperator.Like))
    aParam.push(new SQLParamArray("txtStockKeepingUnit", sku, SQLParamArray.enSQLOperator.Like, SQLParamArray.enSQLLogicalOperator.AND, new DBFieldFunction(SQLParamArray.enDBFieldFunction.Replace, "-", "")))
    aParamList.push(aParam);

    this.showSpinner = true;

    oThis.productCodeService.getProductCodesParamArray(aParamList).subscribe(productCodes => {

      oThis.productCodes = productCodes;

      this.showSpinner = false;

    }, err => {
      console.log(err);

      this.showSpinner = false;

    });


  }

  selectedProductCodes(productCodes: dtoProductCode[]) {

    if (productCodes == null || productCodes.length == 0) {
      return;
    }

    this.form.controls['txtSKU'].setValue(productCodes[0].txtStockKeepingUnit);
    this.quantityUOM.nativeElement.innerHTML = productCodes[0].txtQuantityUoM;
    this.selectedProductCode = productCodes[0];

    console.log(productCodes);

  }

}
