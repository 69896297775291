import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { dtoStockItemQuantitySummary } from '../business-objects/dto-stock-item-quantity-summary.bo';

@Injectable()
export class dtoStockItemQuantitySummaryService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

  getdtoStockItemQuantitySummary(identifier: number) {
    return this.http.get<dtoStockItemQuantitySummary>(this._baseUrl + 'api/dtoStockItemQuantitySummary/GetdtoStockItemQuantitySummary/' + identifier);
	}

	getdtoStockItemQuantitySummaryParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<dtoStockItemQuantitySummary[]>(this._baseUrl + 'api/dtoStockItemQuantitySummary/GetdtoStockItemQuantitySummaryParamArray/', aParam);
	}

	getAlldtoStockItemQuantitySummarys() {
		return this.http.get<dtoStockItemQuantitySummary[]>(this._baseUrl + 'api/dtoStockItemQuantitySummary/GetAlldtoStockItemQuantitySummary/');
	}

	//getAlldtodtoStockItemQuantitySummarys() {
	//	return this.http.get<dtodtoStockItemQuantitySummary[]>(this._baseUrl + 'api/dtoStockItemQuantitySummary/GetAlldtoStockItemQuantitySummary/');
	//}

	createdtoStockItemQuantitySummary(dtoStockItemQuantitySummary: dtoStockItemQuantitySummary) {
		return this.http.post<dtoStockItemQuantitySummary>(this._baseUrl + 'api/dtoStockItemQuantitySummary/CreatedtoStockItemQuantitySummary/', dtoStockItemQuantitySummary);
	}

	updatedtoStockItemQuantitySummary(dtoStockItemQuantitySummary: dtoStockItemQuantitySummary) {
		return this.http.put<dtoStockItemQuantitySummary>(this._baseUrl + 'api/dtoStockItemQuantitySummary/UpdatedtoStockItemQuantitySummary/', dtoStockItemQuantitySummary);
	}

	deletedtoStockItemQuantitySummary(dtoStockItemQuantitySummaryId: string) {
		return this.http.delete(this._baseUrl + 'api/dtoStockItemQuantitySummary/DeletedtoStockItemQuantitySummary/' + dtoStockItemQuantitySummaryId);
	}

	async getdtoStockItemQuantitySummaryPromise(identifier: number) {
		return new Promise<dtoStockItemQuantitySummary>(resolve => {
      this.getdtoStockItemQuantitySummary(identifier).subscribe(dtoStockItemQuantitySummary => {
				resolve(dtoStockItemQuantitySummary);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoStockItemQuantitySummary records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async getdtoStockItemQuantitySummaryParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<dtoStockItemQuantitySummary[]>(resolve => {
			this.getdtoStockItemQuantitySummaryParamArray(aParam).subscribe(dtoStockItemQuantitySummary => {
				resolve(dtoStockItemQuantitySummary);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoStockItemQuantitySummary records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async updatedtoStockItemQuantitySummaryPromise(dtoStockItemQuantitySummary: dtoStockItemQuantitySummary) {
		return new Promise<dtoStockItemQuantitySummary>(resolve => {
			this.updatedtoStockItemQuantitySummary(dtoStockItemQuantitySummary).subscribe(dtoStockItemQuantitySummary => {
				resolve(dtoStockItemQuantitySummary);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating dtoStockItemQuantitySummary record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createdtoStockItemQuantitySummaryPromise(dtoStockItemQuantitySummary: dtoStockItemQuantitySummary) {
		return new Promise<dtoStockItemQuantitySummary>(resolve => {
			this.createdtoStockItemQuantitySummary(dtoStockItemQuantitySummary).subscribe(dtoStockItemQuantitySummary => {
				resolve(dtoStockItemQuantitySummary);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating dtoStockItemQuantitySummary record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async deletedtoStockItemQuantitySummaryPromise(dtoStockItemQuantitySummaryId: string) {
		return new Promise<boolean>(resolve => {
			this.deletedtoStockItemQuantitySummary(dtoStockItemQuantitySummaryId).subscribe(dtoStockItemQuantitySummary => {
				resolve(true);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error removing dtoStockItemQuantitySummary record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(false);
			});
		});
	}

}
