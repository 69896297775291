export interface BoardTallyRecord
{
	blnActive: boolean;
	blnActual: boolean;
	blnAddToOrder: boolean;
	blnArchive: boolean;
	blnBroadcast: boolean;
	blnCheck1: boolean;
	blnCheck2: boolean;
	blnClearance: boolean;
	blnConsignment: boolean;
	blnConsignmentDelivered: boolean;
	blnCustomersTimber: boolean;
	blnDelivered: boolean;
	blnFinalDelivery: boolean;
	blnFollowUp: boolean;
	blnGradeCorrect: boolean;
	blnHasThumbnailImage: boolean;
	blnInvoiced: boolean;
	blnJoinery: boolean;
	blnLawsonCresStock: boolean;
	blnLayersCorrect: boolean;
	blnLeaveOffSticker: boolean;
	blnMelbourneStock: boolean;
	blnMelbourneStockDelivered: boolean;
	blnMinimumStock: boolean;
	blnMultiDelivery: boolean;
	blnMultiItemPack: boolean;
	blnNextBrisbaneDelivery: boolean;
	blnNextDelivery: boolean;
	blnNextDelivery2: boolean;
	blnNextLocalDelivery: boolean;
	blnOnhold: boolean;
	blnPrecoated: boolean;
	blnSetLength: boolean;
	blnStickerPrinted: boolean;
	blnStockTake: boolean;
	blnTradeRetailOnly: boolean;
	dte1stAdjDate: Date;
	dte2ndAdjDate: Date;
	dte3rdAdjDate: Date;
	dte4thAdjDate: Date;
	dte5thAdjDate: Date;
	dte6thAdjDate: Date;
	dteAddToOrder: Date;
	dteArchiveDate: Date;
	dteDate: Date;
	dteDateCreated: Date;
	dteDateSold: Date;
	dteDebugUpdate: Date;
	dteFollowUp: Date;
	dteLastUpdated: Date;
	dteOnhold: Date;
	dteStatusDate: Date;
	dteStockTake: Date;
	flt1stAdjLM: number;
	flt1stAdjSubTotalLM: number;
	flt2ndAdjLM: number;
	flt2ndAdjSubTotalLM: number;
	flt3rdAdjLM: number;
	flt3rdAdjSubTotalLM: number;
	flt4rthAdjLM: number;
	flt4rthAdjSubTotalLM: number;
	flt5thAdjLM: number;
	flt5thAdjSubTotalLM: number;
	flt6thAdjLM: number;
	flt6thAdjSubTotalLM: number;
	fltApprovedPrice: number;
	fltAuctionReserve: number;
	fltCost: number;
	fltDia: number;
	fltExThickness: number;
	fltExWidth: number;
	fltNominalLength: number;
	fltNominalThickness: number;
	fltNominalWidth: number;
	fltOrderItemLength: number;
	fltOriginalLM: number;
	fltQuantity: number;
	fltSalePrice: number;
	fltSupplierPrice: number;
	fltWastePercentage: number;
	gu1stAdjChildRecordId: string;
	gu1stAdjParentRecordId: string;
	gu2ndAdjChildRecordId: string;
	gu2ndAdjParentRecordId: string;
	gu3rdAdjChildRecordId: string;
	gu3rdAdjParentRecordId: string;
	gu4thAdjChildRecordId: string;
	gu4thAdjParentRecordId: string;
	gu5thAdjChildRecordId: string;
	gu5thAdjParentRecordId: string;
	gu6thAdjChildRecordId: string;
	gu6thAdjParentRecordId: string;
	guBoardGradeId: string;
	guBoardOrderId: string;
	guChargeId: string;
	guCustomerId: string;
	guEmployeeEnteredId: string;
	guEmployeeOnHoldId: string;
	guMachineRunId: string;
	guMoulderId: string;
	guOrderItemId: string;
	guPackedById: string;
	guProductCodeId: string;
	guProductProfileId: string;
	guProfileId: string;
	guSpeciesId: string;
	guStockContainerId: string;
	int1stAdjNoOfLayers: number;
	int2ndAdjNoOfLayers: number;
	int3rdAdjNoOfLayers: number;
	int4thAdjNoOfLayers: number;
	int5thAdjNoOfLayers: number;
	int6thAdjNoOfLayers: number;
	intApprovedPriceTpye: number;
	intApprovedPriceType: number;
	intCostType: number;
	intCoverWidthS1: number;
	intCoverWidthS2: number;
	intCoverWidthS3: number;
	intCoverWidthS4: number;
	intGrade: number;
	intHazardLevel: number;
	intItemNo: number;
	intLaminatedBoards: number;
	intMCPercentS1: number;
	intMCPercentS2: number;
	intMCPercentS3: number;
	intMCPercentS4: number;
	intMoistureType: number;
	intNoOfLayers: number;
	intNoOfLayers1: number;
	intNumberOfPhotos: number;
	intPackNo: number;
	intPieceCount: number;
	intQuantity: number;
	intQuantityOLD: number;
	intQuantityUoM: number;
	intStatus: number;
	intStructureGrade: number;
	intThickness: number;
	intTotalLM: number;
	intTotalLMOLD: number;
	intTreatmentType: number;
	intWebPublishStatus: number;
	intWidth: number;
	rowguid: string;
	txt1stCheckedBy: string;
	txt2ndCheckedBy: string;
	txt3rdCheckedBy: string;
	txt4thCheckedBy: string;
	txt5thCheckedBy: string;
	txt6thCheckedBy: string;
	txtArchiveComments: string;
	txtAuctionNumber: string;
	txtChargeId: string;
	txtComments: string;
	txtDebugComments: string;
	txtHoldComments: string;
	txtLocation: string;
	txtProduct: string;
	txtQuantityUoM: string;
	txtSpecies: string;
	txtStickerComments: string;
	txtSupplier: string;
	txtSupplierPrice: string;
}

export class BoardTallyRecord
{
	blnActive: boolean;
	blnActual: boolean;
	blnAddToOrder: boolean;
	blnArchive: boolean;
	blnBroadcast: boolean;
	blnCheck1: boolean;
	blnCheck2: boolean;
	blnClearance: boolean;
	blnConsignment: boolean;
	blnConsignmentDelivered: boolean;
	blnCustomersTimber: boolean;
	blnDelivered: boolean;
	blnFinalDelivery: boolean;
	blnFollowUp: boolean;
	blnGradeCorrect: boolean;
	blnHasThumbnailImage: boolean;
	blnInvoiced: boolean;
	blnJoinery: boolean;
	blnLawsonCresStock: boolean;
	blnLayersCorrect: boolean;
	blnLeaveOffSticker: boolean;
	blnMelbourneStock: boolean;
	blnMelbourneStockDelivered: boolean;
	blnMinimumStock: boolean;
	blnMultiDelivery: boolean;
	blnMultiItemPack: boolean;
	blnNextBrisbaneDelivery: boolean;
	blnNextDelivery: boolean;
	blnNextDelivery2: boolean;
	blnNextLocalDelivery: boolean;
	blnOnhold: boolean;
	blnPrecoated: boolean;
	blnSetLength: boolean;
	blnStickerPrinted: boolean;
	blnStockTake: boolean;
	blnTradeRetailOnly: boolean;
	dte1stAdjDate: Date;
	dte2ndAdjDate: Date;
	dte3rdAdjDate: Date;
	dte4thAdjDate: Date;
	dte5thAdjDate: Date;
	dte6thAdjDate: Date;
	dteAddToOrder: Date;
	dteArchiveDate: Date;
	dteDate: Date;
	dteDateCreated: Date;
	dteDateSold: Date;
	dteDebugUpdate: Date;
	dteFollowUp: Date;
	dteLastUpdated: Date;
	dteOnhold: Date;
	dteStatusDate: Date;
	dteStockTake: Date;
	flt1stAdjLM: number;
	flt1stAdjSubTotalLM: number;
	flt2ndAdjLM: number;
	flt2ndAdjSubTotalLM: number;
	flt3rdAdjLM: number;
	flt3rdAdjSubTotalLM: number;
	flt4rthAdjLM: number;
	flt4rthAdjSubTotalLM: number;
	flt5thAdjLM: number;
	flt5thAdjSubTotalLM: number;
	flt6thAdjLM: number;
	flt6thAdjSubTotalLM: number;
	fltApprovedPrice: number;
	fltAuctionReserve: number;
	fltCost: number;
	fltDia: number;
	fltExThickness: number;
	fltExWidth: number;
	fltNominalLength: number;
	fltNominalThickness: number;
	fltNominalWidth: number;
	fltOrderItemLength: number;
	fltOriginalLM: number;
	fltQuantity: number;
	fltSalePrice: number;
	fltSupplierPrice: number;
	fltWastePercentage: number;
	gu1stAdjChildRecordId: string;
	gu1stAdjParentRecordId: string;
	gu2ndAdjChildRecordId: string;
	gu2ndAdjParentRecordId: string;
	gu3rdAdjChildRecordId: string;
	gu3rdAdjParentRecordId: string;
	gu4thAdjChildRecordId: string;
	gu4thAdjParentRecordId: string;
	gu5thAdjChildRecordId: string;
	gu5thAdjParentRecordId: string;
	gu6thAdjChildRecordId: string;
	gu6thAdjParentRecordId: string;
	guBoardGradeId: string;
	guBoardOrderId: string;
	guChargeId: string;
	guCustomerId: string;
	guEmployeeEnteredId: string;
	guEmployeeOnHoldId: string;
	guMachineRunId: string;
	guMoulderId: string;
	guOrderItemId: string;
	guPackedById: string;
	guProductCodeId: string;
	guProductProfileId: string;
	guProfileId: string;
	guSpeciesId: string;
	guStockContainerId: string;
	int1stAdjNoOfLayers: number;
	int2ndAdjNoOfLayers: number;
	int3rdAdjNoOfLayers: number;
	int4thAdjNoOfLayers: number;
	int5thAdjNoOfLayers: number;
	int6thAdjNoOfLayers: number;
	intApprovedPriceTpye: number;
	intApprovedPriceType: number;
	intCostType: number;
	intCoverWidthS1: number;
	intCoverWidthS2: number;
	intCoverWidthS3: number;
	intCoverWidthS4: number;
	intGrade: number;
	intHazardLevel: number;
	intItemNo: number;
	intLaminatedBoards: number;
	intMCPercentS1: number;
	intMCPercentS2: number;
	intMCPercentS3: number;
	intMCPercentS4: number;
	intMoistureType: number;
	intNoOfLayers: number;
	intNoOfLayers1: number;
	intNumberOfPhotos: number;
	intPackNo: number;
	intPieceCount: number;
	intQuantity: number;
	intQuantityOLD: number;
	intQuantityUoM: number;
	intStatus: number;
	intStructureGrade: number;
	intThickness: number;
	intTotalLM: number;
	intTotalLMOLD: number;
	intTreatmentType: number;
	intWebPublishStatus: number;
	intWidth: number;
	rowguid: string;
	txt1stCheckedBy: string;
	txt2ndCheckedBy: string;
	txt3rdCheckedBy: string;
	txt4thCheckedBy: string;
	txt5thCheckedBy: string;
	txt6thCheckedBy: string;
	txtArchiveComments: string;
	txtAuctionNumber: string;
	txtChargeId: string;
	txtComments: string;
	txtDebugComments: string;
	txtHoldComments: string;
	txtLocation: string;
	txtProduct: string;
	txtQuantityUoM: string;
	txtSpecies: string;
	txtStickerComments: string;
	txtSupplier: string;
	txtSupplierPrice: string;
}

export namespace BoardTallyRecord
{
	export enum enQuantityUoM
	{
		Each,
		M3,
		LM,
		M2,
		KG,
		TON
	}
}

export namespace BoardTallyRecord
{
	export enum enWebPublishStatus
	{
		NotPublished,
		ReadyForPublish,
		Published
	}
}

