<div class="product-detail-view-container">

  <div class="product-detail-view-grid">
    <div class="nav-div">
      <button class="closeButton" mat-flat-button color="primary" type="button" (click)="closeStockDetail()"><< Back</button>
    </div>

    <div class="product-detail-grid">

      <div class="product-detail">

        <div class="product-detail-wrapper">
          <div class="description-container">
            <div class="product-view-description product-name">{{selectedStockSummary.txtProductName}}</div>
            <div class="product-view-description profile-name">{{selectedStockSummary.txtProfileName}}</div>
            <div class="product-view-description width">{{selectedStockSummary.intWidth}}</div>
            <div class="product-view-description width-thickness-divider" *ngIf="selectedStockSummary.intWidth != null && selectedStockSummary.intThickness != null">x</div>
            <div class="product-view-description thickness">{{selectedStockSummary.intThickness}}mm</div>
            <div class="product-view-description species" [innerHTML]="selectedStockSummary.txtSpeciesName | camelCase"></div>
            <div class="product-view-description grade" [innerHTML]="selectedStockSummary.txtGradeName == null ? '' : (selectedStockSummary.txtGradeName.toLowerCase() == 'none' ? '' : selectedStockSummary.txtGradeName + ' Grade' | camelCase)"></div>
          </div>



          <div class="price-qty-container-grid">
            <div class="pricing-container">
              <div class="price-dollars">
                {{formatDollars(selectedStockSummary.fltApprovedPrice)}}
              </div>
              <div class="price-cents">
                {{formatCents(selectedStockSummary.fltApprovedPrice)}}
              </div>
              <div class="price-type">
                {{formatPriceType(selectedStockSummary.txtApprovedPriceText, selectedStockSummary.fltApprovedPrice)}}
              </div>
            </div>

            <div class="available-qty">
              <div class="available-qty-label">Available Quantity</div>
              <div class="random-lengths" *ngIf="showRandomLengths">
                <div class="pack-number-heading set-length-heading">Pack No</div>
                <div class="quantity-heading set-length-heading">Qty</div>
                <div class="uom-heading set-length-heading"></div>
                @for(stockItemQtySum of stockItemQtySummary; track stockItemQtySum; let idx = $index; let last = $last){
                <div class="available-qty-row-wrapper">
                  <div class="stock-item-pack-no" [ngClass]="idx % 2 == 1 ? '' : 'alternate-row' + (last == true ? ' avail-qty-last-row-left' : '')">{{idx == 0 || (idx > 0 && stockItemQtySummary[idx-1] != null && stockItemQtySummary[idx-1].intIdentifier != stockItemQtySummary[idx].intIdentifier) == true ? stockItemQtySum.intIdentifier : ''}}</div>
                  <div class="stock-item-quantity" [ngClass]="idx % 2 == 1 ? '' : 'alternate-row'">{{stockItemQtySum.fltQuantity.toFixed(0)}}</div>
                  <div class="stock-item-uom" [ngClass]="(idx % 2 == 1 ? '' : 'alternate-row') + (last == true ? ' avail-qty-last-row-right' : '')">{{stockItemQtySum.txtQuantityUoM}}</div>
                </div>
                }

              </div>

              <div class="set-lengths" *ngIf="showSetLengths">
                <div class="pack-number-heading set-length-heading">Pack No</div>
                <div class="quantity-heading set-length-heading">Qty</div>
                <div class="divider-heading set-length-heading"></div>
                <div class="length-heading set-length-heading">Length</div>
                @for(stockItemQtySum of stockItemQtySummary; track stockItemQtySum; let idx = $index){
                <div class="stock-item-quantity">{{idx == 0 || (idx > 0 && stockItemQtySummary[idx-1] != null && stockItemQtySummary[idx-1].intIdentifier != stockItemQtySummary[idx].intIdentifier) == true ? stockItemQtySum.intIdentifier : ''}}</div>
                <div class="stock-item-quantity">{{stockItemQtySum.fltQuantity}}</div>
                <div class="stock-item-legnth-divider">/</div>
                <div class="stock-item-legnth">{{stockItemQtySum.intTotalLM.toString().indexOf(".") > -1 ? stockItemQtySum.intTotalLM : stockItemQtySum.intTotalLM + '.0'}}m</div>
                }

              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="product-images">

        <div class="loading-photos" *ngIf="(fileAttachments == null || fileAttachments.length == 0) && boardTallyRecordPhotos != null && boardTallyRecordPhotos.length > 0">Loading Photos....</div>
        <div class="no-photos" *ngIf="fileAttachments == null || fileAttachments.length == 0 && (boardTallyRecordPhotos == null || boardTallyRecordPhotos.length == 0)">There are no photos</div>
        <div class="photo-viewer-container" *ngIf="fileAttachments != null && fileAttachments.length > 0">
          <div class="back-image">
            <mat-icon matTooltip="" class="keyboard-double-arrow-left material-symbols-outlined" (click)="backPhoto()">keyboard_double_arrow_left</mat-icon>
          </div>
          <div class="photo-column">
            <div class="slider">
              <div class="slider-image-numbers">
                @for(boardTallyRecordPhoto of boardTallyRecordPhotos; track boardTallyRecordPhoto; let idx = $index){
                <div class="image-numbers" [ngClass]="fileAttachments[idx] == null ? 'non-loaded-image-number' : photoIndex == idx ? 'image-numbers-active' : 'image-numbers'" id="slide-{{idx}}" (click)="slideToNext(idx)">{{idx+1}}</div>
                }
              </div>
              <div id="slidesContainer" class="slides">
                @for(fileAttachment of fileAttachments; track fileAttachment; let idx = $index){
                <div id="slide-image-{{idx}}" class="slide-image-container">
                  <img *ngIf="fileAttachment != null" [src]="fileAttachment.dataURI()" width="400" alt="Image" class="product-image-photo" (click)="openPhoto(fileAttachment)" (touchmove)="touchimage($event)" />
                </div>
                }


              </div>
            </div>
          </div>
          <div class="forward-image">
            <mat-icon matTooltip="" class="keyboard-double-arrow-right material-symbols-outlined" (click)="forwardPhoto(productImage)">keyboard_double_arrow_right</mat-icon>
          </div>

        </div>
      </div>



    </div>


  </div>



</div>
