import { Injectable } from "@angular/core";


@Injectable()
export class UtilityService {

  async timeWait(time: number) {
    return new Promise<boolean>(resolve => {
      setTimeout(() => {
        //console.log("in timeWait")
        resolve(true);
      }, time);
    });
  }

  CString(value) {
    return value == null || undefined ? "" : value.toString();
  }

  CNumber(value) {
    return value == null || undefined ? 0 : Number(value);
  }

  formatDollars(val: number) {
    if (val == null || val == 0) {
      return "Price TBA";
    }

    let dollars: number = Number(val.toString().substring(0, val.toString().indexOf('.')));
    if (dollars == 0) {
      return "$0";
    }

    return "$" + dollars.toString();
  }


  formatCents(val: number) {
    if (val == null || val == 0) {
      return "";
    }

    let cents: number = val != null ? Number(val.toString().substring(val.toString().indexOf('.') + 1, val.toString().length)) : 0;
    if (cents == 0) {
      return ".00";
    }

    if (cents.toString().length == 1) {
      return "." + cents.toString() + "0";
    }

    return "." + cents.toString();
  }

  formatPriceType(val: string, price: number) {

    //console.log("price : ", val);

    if (val == null || price == null || price == 0) {
      return "";
    }


    let priceType: string = val != null ? val.toString().substring(val.toString().indexOf('/') + 1, val.toString().length) : "";

    //console.log("price type : ", val);


    if (priceType.toLowerCase() == "lm") {
      priceType = "per 1 lineal metre";
    }

    if (priceType.toLowerCase() == "m2") {
      priceType = "per 1 square metre";
    }

    if (priceType.toLowerCase() == "m3") {
      priceType = "per 1 cubic metre";
    }

    if (priceType.toLowerCase() == "ton") {
      priceType = "per 1 tonne";
    }


    //console.log("priceType: " + priceType);

    return priceType;
  }

}
