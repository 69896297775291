export interface BoardTallyRecordHistory
{
	blnArchived: boolean;
	blnQuarantined: boolean;
	dteArchivedDate: Date;
	dteCreated: Date;
	dteDateComputerChanged: Date;
	dteDateReturned: Date;
	dteLastUpdated: Date;
	dteQuarantinedDate: Date;
	dteStatusDate: Date;
	fltApprovedPrice: number;
	fltQuantity: number;
	fltQuantityOLD: number;
	fltTotalLM: number;
	fltTotalLMOLD: number;
	guBoardTallyRecordId: string;
	guEmployeeId: string;
	guGeoStockLocationId: string;
	guMoulderId: string;
	guOrderItemId: string;
	guProductCodeId: string;
	guStockContainerTypeId: string;
	intApprovedPriceTpye: number;
	intApprovedPriceType: number;
	intNoOfLayers: number;
	intPieces: number;
	intQuantityUoM: number;
	intStatus: number;
	intWebPublishStatus: number;
	rowguid: string;
	txtArchiveComments: string;
	txtBoardTallyRecordComments: string;
	txtChangedData: string;
	txtLocation: string;
	txtQuarantinedComments: string;
	txtStockContainerComments: string;
}

export class BoardTallyRecordHistory
{
	blnArchived: boolean;
	blnQuarantined: boolean;
	dteArchivedDate: Date;
	dteCreated: Date;
	dteDateComputerChanged: Date;
	dteDateReturned: Date;
	dteLastUpdated: Date;
	dteQuarantinedDate: Date;
	dteStatusDate: Date;
	fltApprovedPrice: number;
	fltQuantity: number;
	fltQuantityOLD: number;
	fltTotalLM: number;
	fltTotalLMOLD: number;
	guBoardTallyRecordId: string;
	guEmployeeId: string;
	guGeoStockLocationId: string;
	guMoulderId: string;
	guOrderItemId: string;
	guProductCodeId: string;
	guStockContainerTypeId: string;
	intApprovedPriceTpye: number;
	intApprovedPriceType: number;
	intNoOfLayers: number;
	intPieces: number;
	intQuantityUoM: number;
	intStatus: number;
	intWebPublishStatus: number;
	rowguid: string;
	txtArchiveComments: string;
	txtBoardTallyRecordComments: string;
	txtChangedData: string;
	txtLocation: string;
	txtQuarantinedComments: string;
	txtStockContainerComments: string;
}

export namespace BoardTallyRecordHistory
{
	export enum enChangeReason
	{
		Created,
		Quantity,
		Location,
		StockTransfer,
		Archived,
		StockTransferAndArchived,
		ProductCode,
		Moulder,
		StockTransferAndQuantityDecrease,
		StockTransferAndQuantityIncrease,
		StockContainerType,
		WebPublishStatus
	}
}

