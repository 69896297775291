import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { dtoProductTypeCategoryGrouped } from '../../_shared/business-objects/dto-product-type-category-grouped.bo';
import { dtoWebPublishStockSummary } from '../../_shared/business-objects/dto-web-publish-stock-summary.bo';
import { SQLParamArray } from '../../_shared/business-objects/sql-param-array';
import { UtilityService } from '../../_shared/services/app-services/utility.service';
import { dtoProductTypeCategoryGroupedService } from '../../_shared/services/dto-product-type-category-grouped.service';
import { GuestService } from '../../_shared/services/guest.service';

@Component({
  selector: 'app-stock-for-sale',
  templateUrl: './stock-for-sale.component.html',
  styleUrl: './stock-for-sale.component.scss'
})
export class StockForSaleComponent implements OnInit {

  @Output() hideNavEvent = new EventEmitter<boolean>();

  stockItems: dtoWebPublishStockSummary[];
  stockItemsAll: dtoWebPublishStockSummary[];
  productTypeCategories: dtoProductTypeCategoryGrouped[];
  viewDetail: boolean;
  selectedStockSummary: dtoWebPublishStockSummary;
  selectedTileId: number;

  categoryParam: string;
  productParam: string;
  profileParam: string;
  widthParam: number;
  thicknessParam: number;
  speciesParam: string;
  visGradeParam: string;
  structGradeParam: string;


  constructor(private guestService: GuestService, private route: ActivatedRoute, private utilitityService: UtilityService, private dtoProductTypeCategoryGroupedService: dtoProductTypeCategoryGroupedService) {
    this.viewDetail = false;
  }


  ngOnInit(): void {

    this.loadData()

  }

  async loadData() {

    await this.getParameters();

    //console.log("about to get stock for sale");
    await this.getStockForSale(this.categoryParam, this.productParam, this.profileParam, this.widthParam, this.thicknessParam, this.speciesParam, this.visGradeParam, this.structGradeParam);

    this.productTypeCategories = await this.getProductTypeCategories();

    //console.log("pt categories: ", this.productTypeCategories);

  }

  async getProductTypeCategories(): Promise<dtoProductTypeCategoryGrouped[]> {

    let aParamList: SQLParamArray[][] = [];
    let categories: dtoProductTypeCategoryGrouped[] = await this.guestService.getdtoProductTypeCategoryGroupedParamArrayPromise(aParamList);
    let categoriesFilted: dtoProductTypeCategoryGrouped[] = [];
    if (this.stockItems != null && this.stockItems.length > 0) {
      let categoryIds: string[] = [...new Set(this.stockItems.map(item => item.guProductTypeCategoryId))];
      let allCategoryIds: string[] = this.stockItems.map(item => item.guProductTypeCategoryId);
      let counted = allCategoryIds.reduce((acc, val) => {
        acc[val] = (acc[val] || 0) + 1;
        return acc;
      }, {});

      //console.log("counted: ", counted); // { a: 2, b: 3, c: 1 }
      //console.log("categoryIds: ", categoryIds);

      this.productTypeCategories = [];
      for (let i = 0; i <= categories.length - 1; i++) {

        if (counted[categories[i].rowguid] > 0) {
          categories[i].intNumberOfCategories = counted[categories[i].rowguid];
          categoriesFilted.push(categories[i]);
        }
        
      }


    }

    return categoriesFilted;
  }


  async getParameters(): Promise<boolean> {

    
    this.route.queryParams.subscribe(async params => {

      if (params != null) {
        this.categoryParam = this.utilitityService.CString(params["category"]);
        this.productParam = this.utilitityService.CString(params["product"]);
        this.profileParam = this.utilitityService.CString(params["profile"]);
        this.widthParam = this.utilitityService.CNumber(params["width"]);
        this.thicknessParam = this.utilitityService.CNumber(params["thickness"]);
        this.speciesParam = this.utilitityService.CString(params["species"]);
        this.visGradeParam = this.utilitityService.CString(params["visgrade"]);
        this.structGradeParam = this.utilitityService.CString(params["structgrade"]);
        //console.log("product param: ", this.productParam);
        return true;
      }

    });

    return false;

  }


  async getStockForSale(category: string, product: string, profile: string, width: number, thickness: number, species: string, visGrade: string, structGrade: string) {



    this.stockItems = await this.guestService.getSlobStockSummaryPromise(category, product, profile, width, thickness, species, visGrade, structGrade);
    this.stockItemsAll = this.stockItems;

    if (this.stockItems == null) {
      return;
    }


    this.stockItems.sort(

      (a, b) => {
        // Sort by txtProductName (ascending)
        if (a.txtProductName < b.txtProductName) return -1;
        if (a.txtProductName > b.txtProductName) return 1;
        // Sort by intWidth (descending)
        if (a.intWidth < b.intWidth) return -1;
        if (a.intWidth > b.intWidth) return 1;
        // Sort by intThickness (ascending)
        if (a.intThickness < b.intThickness) return -1;
        if (a.intThickness > b.intThickness) return 1;
        // Sort by txtSpecies (ascending)
        if (a.txtSpeciesName < b.txtSpeciesName) return -1;
        if (a.txtSpeciesName > b.txtSpeciesName) return 1;
        // Sort by txtGradeName (ascending)
        if (a.txtGradeName < b.txtGradeName) return -1;
        if (a.txtGradeName > b.txtGradeName) return 1;
      }

    );
    //console.log(this.stockItems);
  }

  viewProductDetail(slobStockSummaryItem: [dtoWebPublishStockSummary,number]) {

    this.selectedStockSummary = slobStockSummaryItem[0];
    this.selectedTileId = slobStockSummaryItem[1];

    //console.log("slob stock summary", slobStockSummaryItem);


  }

  closeProductDetail(index: number) {

    this.selectedStockSummary = null;

    //console.log("index: ", index);
    setTimeout(() => {
      let el: HTMLElement = document.getElementById("tile" + index.toString());
      if (el != null) {

        const rect = el.getBoundingClientRect();
        window.scrollTo({ top: rect.top - 150, left: rect.left, behavior: 'smooth' }); /* subtract 20px from top */


        //el.scrollIntoView({ behavior: 'smooth' });
        //console.log("el: ", el);
      }

    }, 500);

  }

  selectedProductTypeCategories(categories: dtoProductTypeCategoryGrouped[]) {

    this.stockItems = this.stockItemsAll;

    if (categories == null || categories.length == 0) {
      return;
    }

    //console.log("categories", categories);
    const cats:string[] = categories.map(a => a.rowguid);

    //const filteredArr = this.stockItems.filter((item) => !categories.includes(item.guProductTypeCategoryId));
    const filteredStockItems = this.stockItems.filter(obj => cats.includes(obj.guProductTypeCategoryId));

    this.stockItems = filteredStockItems;

  }
}
