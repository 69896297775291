<div class="delivery-docket-print-screen-container" id="deliveryDocketPrintScreenContainer">
  <div class="delivery-docket-print-container" id="deliveryDocketPrintContainer">
    <div class="header-wrapper">
      <div class="header-container">
        <div class="header-logo">
          <img src="../../../../assets/img/BlackLogo.bmp" width="40" height="40" />
        </div>
        <div class="divider-wrapper">
          <div class="header-column-2"></div>
        </div>
        <div class="header-column-3" *ngIf="false">
          <div class="header-row-1">
            <div>C</div>
            <div>o</div>
            <div>f</div>
            <div>f</div>
            <div>s</div>
            <div></div>
            <div>H</div>
            <div>a</div>
            <div>r</div>
            <div>b</div>
            <div>o</div>
            <div>u</div>
            <div>r</div>
          </div>
          <div class="header-row-2">
            <div>H</div>
            <div>A</div>
            <div>R</div>
            <div>D</div>
            <div>W</div>
            <div>O</div>
            <div>O</div>
            <div>D</div>
            <div>S</div>
          </div>
          <div class="header-website" *ngIf="false">www.coffshardwoods.com.au</div>
        </div>

        <div class="header-column-3A">
          <div class="header-row-1A">
            <div><h3>Coffs Harbour Hardwoods</h3></div>
          </div>
        </div>

      </div>
    </div>
    <div class="delivery-docket-heading">Delivery Docket</div>
    <div class="print-buton">
      <button class="print-button" type="button" mat-flat-button color="primary" (click)="print()" *ngIf="this.deliveryDocket != null && pdfMode == false">Print Delivery Docket</button>
    </div>
    <div class="delivery-docket-info" *ngIf="deliveryDocket != null">
      <div class="docket-heading-label delivery-docket-no-label">Docket No:</div>
      <div class="docket-heading-cell delivery-docket-no">{{deliveryDocket.intDocketNo}}</div>
      <div class="docket-heading-label delivery-docket-date-loaded-label">Status:</div>
      <div class="docket-heading-cell delivery-docket-date-loaded">{{deliveryDocket.txtStatus}}</div>
      <div class="docket-heading-label delivery-docket-date-loaded-label">Status Date:</div>
      <div class="docket-heading-cell delivery-docket-date-loaded">{{deliveryDocket.dteStatusDate | date: 'dd/MM/yyyy'}}</div>
      <div class="docket-heading-label delivery-docket-hauler-label">Shipped Via:</div>
      <div class="docket-heading-cell delivery-docket-hauler">{{deliveryDocket.txtHaulingCompanyName}}</div>
      <div class="docket-heading-label delivery-docket-date-loaded-label">Driver:</div>
      <div class="docket-heading-cell delivery-docket-date-loaded">{{deliveryDocket.txtHaulingCompanyDriverFirstName}} {{deliveryDocket.txtHaulingCompanyDriverLastName}}</div>
      <div class="docket-heading-label delivery-docket-customer-label">Customer:</div>
      <div class="docket-heading-cell delivery-docket-customer">{{deliveryDocket.txtCustomerName}}</div>
      <div class="docket-heading-label delivery-docket-contact-delivery-address-label">Delivery Address:</div>
      <div class="docket-heading-cell delivery-docket-contact-delivery-address">
        <div>{{(deliveryDocket.txtDelOrderItemAddressTownStatePostcode != null && deliveryDocket.txtDelOrderItemAddressTownStatePostcode != "") ? deliveryDocket.txtDelOrderItemAddressTownStatePostcode : deliveryDocket.txtAddressTownStatePostcode}}</div>
        <div class="delivery-docket-address2" *ngIf="deliveryDocket.txtAddressTownStatePostcode2 != null && deliveryDocket.txtAddressTownStatePostcode2 != ''">Via: {{deliveryDocket.txtAddressTownStatePostcode2}}</div>
        <div class="delivery-docket-address3" *ngIf="deliveryDocket.txtAddressTownStatePostcode3 != null && deliveryDocket.txtAddressTownStatePostcode3 != ''">Via: {{deliveryDocket.txtAddressTownStatePostcode3}}</div>
        <div class="delivery-docket-address3" *ngIf="deliveryDocket.txtAddressTownStatePostcode4 != null && deliveryDocket.txtAddressTownStatePostcode4 != ''">Via: {{deliveryDocket.txtAddressTownStatePostcode4}}</div>
      </div>

      @for(customerDeliveryContact of customerDeliveryContacts; track customerDeliveryContact.rowguid){
      <div class="docket-heading-label delivery-docket-contact-mobile-label">Contact Name:</div>
      <div class="docket-heading-cell delivery-docket-contact-mobile">{{customerDeliveryContact.txtContactFullName}}</div>
      <div class="docket-heading-label delivery-docket-contact-phone-label">Phone:</div>
      <div class="docket-heading-cell delivery-docket-contact-phone">{{customerDeliveryContact.txtContactPhone}}</div>
      <div class="docket-heading-label delivery-docket-contact-mobile-label">Mobile:</div>
      <div class="docket-heading-cell delivery-docket-contact-mobile">{{customerDeliveryContact.txtContactMobile}}</div>
      }
    </div>

    <br />
    <div class="delivery-docket-delivery-address-comments-container" *ngIf="deliveryDocket != null && deliveryDocket.txtDeliveryAddressComments != null && deliveryDocket.txtDeliveryAddressComments != ''">
      <div class="delivery-docket-delivery-address-comments-label">Delivery Address Comments:</div>
      <div class="delivery-docket-delivery-address-comments">{{deliveryDocket.txtDeliveryAddressComments}}</div>
    </div>

    <br />
    <div class="delivery-docket-comments-container" *ngIf="deliveryDocket != null && deliveryDocket.txtComments != null && deliveryDocket.txtComments != ''">
      <div class="delivery-docket-comments-label">Comments:</div>
      <div class="delivery-docket-comments">{{deliveryDocket.txtComments}}</div>
    </div>
    <br />
    <div class="items-container">
      <div class="delivery-docket-items-container">
        <div class="delivery-items-label">Delivery Items</div>
        <div class="delivery-items-container">
          <app-delivery-docket-view class="delivery-docket-view" #appLoadingDocketView [loadingDocketIdInput]="deliveryDocket.rowguid" *ngIf="deliveryDocket != null"></app-delivery-docket-view>
        </div>
      </div>
    </div>

  </div>

  <div class="delivery-docket-bottom" id="deliveryDocketBottom" *ngIf="true">
    <div class="signature-container">
      <div class="signature-heading-signature">PROOF OF DELIVERY</div>

      <div class="name-signature-label align-bottom signature-label">Name:............................................................................</div>
      <div class="signature align-bottom signature-label" *ngIf="signImage != null || signaturePadOpen" id="signatureDiv"><span class="signature-span-label">Signature:</span><img id="signatureImage" height="75" src="{{'data:image/jpg;base64,' +  signImage}}" /></div>
      <div class="signature align-bottom signature-label" *ngIf="signImage == null && signaturePadOpen == false">Signature:............................................................................</div>

      <div class="date-signature-label align-bottom signature-label">Date:............/.............../...............</div>
      <div class="terms-label align-bottom signature-label">* Please read the terms and conditions. *</div>

    </div>
  </div>
</div>

<div id="signaturePad" class="signature-pad-app" *ngIf="false && !hideSignaturePad">
  <app-signature-pad (signedEvent)="saveSignature($event)" (noSignedEvent)="saveNoSignature($event)" (signedCancelledEvent)="closeSignaturePad()" *ngIf="signaturePadOpen"></app-signature-pad>
</div>
<div class="signature-pad-container" *ngIf="false && !hideSignaturePad">
  <div class="save-signature-buton">
    <button class="save-button" type="button" mat-flat-button color="primary" (click)="signDeliveryDocket()">Sign Delivery Docket</button>
  </div>
</div>
