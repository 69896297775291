import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { dtoProductTypeCategoryGrouped } from '../business-objects/dto-product-type-category-grouped.bo';

@Injectable()
export class dtoProductTypeCategoryGroupedService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getdtoProductTypeCategoryGrouped(dtoProductTypeCategoryGroupedId: string) {
		return this.http.get<dtoProductTypeCategoryGrouped>(this._baseUrl + 'api/dtoProductTypeCategoryGrouped/' + dtoProductTypeCategoryGroupedId);
	}

	getdtoProductTypeCategoryGroupedParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<dtoProductTypeCategoryGrouped[]>(this._baseUrl + 'api/dtoProductTypeCategoryGrouped/GetdtoProductTypeCategoryGroupedsParamArray/', aParam);
	}

	getAlldtoProductTypeCategoryGroupeds() {
		return this.http.get<dtoProductTypeCategoryGrouped[]>(this._baseUrl + 'api/dtoProductTypeCategoryGrouped/GetAlldtoProductTypeCategoryGroupeds/');
	}

	//getAlldtodtoProductTypeCategoryGroupeds() {
	//	return this.http.get<dtodtoProductTypeCategoryGrouped[]>(this._baseUrl + 'api/dtoProductTypeCategoryGrouped/GetAlldtoProductTypeCategoryGroupeds/');
	//}

	createdtoProductTypeCategoryGrouped(dtoProductTypeCategoryGrouped: dtoProductTypeCategoryGrouped) {
		return this.http.post<dtoProductTypeCategoryGrouped>(this._baseUrl + 'api/dtoProductTypeCategoryGrouped/CreatedtoProductTypeCategoryGrouped/', dtoProductTypeCategoryGrouped);
	}

	updatedtoProductTypeCategoryGrouped(dtoProductTypeCategoryGrouped: dtoProductTypeCategoryGrouped) {
		return this.http.put<dtoProductTypeCategoryGrouped>(this._baseUrl + 'api/dtoProductTypeCategoryGrouped/UpdatedtoProductTypeCategoryGrouped/', dtoProductTypeCategoryGrouped);
	}

	deletedtoProductTypeCategoryGrouped(dtoProductTypeCategoryGroupedId: string) {
		return this.http.delete(this._baseUrl + 'api/dtoProductTypeCategoryGrouped/DeletedtoProductTypeCategoryGrouped/' + dtoProductTypeCategoryGroupedId);
	}

	async getdtoProductTypeCategoryGroupedPromise(dtoProductTypeCategoryGroupedId: string) {
		return new Promise<dtoProductTypeCategoryGrouped>(resolve => {
			this.getdtoProductTypeCategoryGrouped(dtoProductTypeCategoryGroupedId).subscribe(dtoProductTypeCategoryGrouped => {
				resolve(dtoProductTypeCategoryGrouped);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoProductTypeCategoryGrouped records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async getdtoProductTypeCategoryGroupedParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<dtoProductTypeCategoryGrouped[]>(resolve => {
			this.getdtoProductTypeCategoryGroupedParamArray(aParam).subscribe(dtoProductTypeCategoryGroupeds => {
				resolve(dtoProductTypeCategoryGroupeds);
			}, err => {
				this.alertService.openSnackBarError("Error getting dtoProductTypeCategoryGrouped records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async updatedtoProductTypeCategoryGroupedPromise(dtoProductTypeCategoryGrouped: dtoProductTypeCategoryGrouped) {
		return new Promise<dtoProductTypeCategoryGrouped>(resolve => {
			this.updatedtoProductTypeCategoryGrouped(dtoProductTypeCategoryGrouped).subscribe(dtoProductTypeCategoryGrouped => {
				resolve(dtoProductTypeCategoryGrouped);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating dtoProductTypeCategoryGrouped record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createdtoProductTypeCategoryGroupedPromise(dtoProductTypeCategoryGrouped: dtoProductTypeCategoryGrouped) {
		return new Promise<dtoProductTypeCategoryGrouped>(resolve => {
			this.createdtoProductTypeCategoryGrouped(dtoProductTypeCategoryGrouped).subscribe(dtoProductTypeCategoryGrouped => {
				resolve(dtoProductTypeCategoryGrouped);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating dtoProductTypeCategoryGrouped record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async deletedtoProductTypeCategoryGroupedPromise(dtoProductTypeCategoryGroupedId: string) {
		return new Promise<boolean>(resolve => {
			this.deletedtoProductTypeCategoryGrouped(dtoProductTypeCategoryGroupedId).subscribe(dtoProductTypeCategoryGrouped => {
				resolve(true);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error removing dtoProductTypeCategoryGrouped record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(false);
			});
		});
	}

}