import { Injectable, NgModule, ModuleWithProviders } from '@angular/core';
import { ProductTypeCategoryService } from './_shared/services/product-type-category.service';
import { ProductCodeService } from './_shared/services/product-code.service';
import { ProductTypeService } from './_shared/services/product-type.service';
import { ProductProfileService } from './_shared/services/product-profile.service';
import { SpeciesService } from './_shared/services/species.service';
import { BoardGradeService } from './_shared/services/board-grade.service';
import { BoardTallyRecordService } from './_shared/services/board-tally-record.service';
import { OrderService } from './_shared/services/order.service';
import { OrderItemService } from './_shared/services/order-item.service';
import { CustomerService } from './_shared/services/customer.service';
import { CustomerDeliveryAddressService } from './_shared/services/customer-delivery-address';
import { CustomerContactService } from './_shared/services/customer-contact.service';
import { SharedService } from './_shared/services/shared.service';
import { DeliveryDocketService } from './_shared/services/delivery-docket.service';
import { DeliveryDocketOrderItemService } from './_shared/services/delivery-docket-order-item.service';
import { DeliveryDocketItemService } from './_shared/services/delivery-docket-item.service';
import { StockContainerService } from './_shared/services/stock-container.service';
import { StockContainerTypeService } from './_shared/services/stock-container-type.service';
import { PricingCategoryService } from './_shared/services/pricing-category.service';
import { NavigationService } from './_shared/services/navigation.service';
import { DurabilityClassService } from './_shared/services/durability-class.service';
import { OrderItemProcessService } from './_shared/services/order-item-process.service';
import { OrderItemProcessOrderItemService } from './_shared/services/order-item-process-order-item.service';
import { TaxInvoiceService } from './_shared/services/tax-invoice.service';
import { TaxInvoiceItemService } from './_shared/services/tax-invoice-item.service';
import { AppPrinterService } from './_shared/services/app-printer.service';
import { PriceChangeReasonService } from './_shared/services/price-change-reason.service';
import { AlertService } from './_shared/services/alert.service';
import { BPStocktakeService } from './bpstocktake/bpstocktake.service';
import { TrimingItemReasonForChangeService } from './_shared/services/trimming-item-reason-for-change.service';
import { PoleTrimingItemReasonForChangeService } from './_shared/services/pole-trimming-item-reason-for-change.service';
import { PoleService } from './_shared/services/pole.service';
import { PoleTrimmingItemService } from './_shared/services/pole-trimming-item.service';
import { GeoStockLocationService } from './_shared/services/geo-stock-location.service';
import { IOService } from './_shared/services/io.service';
import { AppUserGeoStockLocationService } from './_shared/services/appuser-geostocklocation.service';
import { AppUserService } from './_shared/services/app-user.service';
import { TableService } from './_shared/services/table.service';
import { ColumnService } from './_shared/services/column.service';
import { dtoDeliveredSalesBySectionDetailAllService } from './_shared/services/dto-delivered-sales-by-section-detail-all.service';
import { dtoBoardTallyRecordNotInStockService } from './_shared/services/dto-board-tally-record-not-in-stock.service';
import { dtoDeliveryDocketService } from './_shared/services/dto-delivery-docket.service';
import { DeliveryDocketStockTransferService } from './_shared/services/delivery-docket-stock-transfer.service';
import { FormService } from './_shared/services/form.service';
import { MoulderService } from './_shared/services/moulder.service';
import { BoardTallyRecordMachineRunService } from './_shared/services/board-tally-record-machine-run.service';
import { BoardTallyRecordMoulderService } from './_shared/services/board-tally-record-moulder.service';
import { BoardTallyRecordProductProfileService } from './_shared/services/board-tally-record-product-profile.service';
import { dtoStockItemDisplayService } from './_shared/services/dto-stock-item-display.service';
import { dtoStockItemService } from './_shared/services/dto-stock-item.service';
import { dtoTPPoleInspectionService } from './_shared/services/dto-tppole-inspection.service';
import { TPPoleInspectionService } from './_shared/services/tppole-inspection.service';
import { EmployeeService } from './_shared/services/employee.service';
import { dtoTPPoleInspectionItemService } from './_shared/services/dto-tppole-inspection-item.service';
import { BoardTallyRecordDeliveryTransferredService } from './_shared/services/board-tally-record-delivery-transferred.service';
import { EmailService } from './_shared/services/email.service';
import { AppPageService } from './_shared/services/app-page.service';
import { AppService } from './_shared/services/app-services/app.service';
import { ClientService } from './_shared/services/client.service';
import { OrdersService } from './_shared/services/orders.service';
import { TPChargeItemService } from './_shared/services/tpcharge-item.service';
import { ElectricalAuthoritySpecService } from './_shared/services/electrical-authority-spec.service';
import { TPPoleInspectionItemService } from './_shared/services/tppole-inspection-item.service';
import { AppUserColumnService } from './_shared/services/app-user-column.service';
import { AppPageColumnService } from './_shared/services/app-page-column.service';
import { dtoAppPageColumnService } from './_shared/services/dto-app-page-column.service';
import { dtoAppUserColumnSetService } from './_shared/services/dto-app-user-column-set.service';
import { AppUserColumnSetService } from './_shared/services/app-user-column-set.service';
import { PoleCubeService } from './_shared/services/pole-cube.service';
import { PositionService } from './_shared/services/position.service';
import { UserPositionService } from './_shared/services/user-position.service';
import { dtoTPPoleInspectionLoadingDocketService } from './_shared/services/dto-tppole-inspection-loading-docket.service';
import { LoadingDocketService } from './_shared/services/loading-docket.service';
import { HaulingCompanyService } from './_shared/services/hauling-company.service';
import { dtoDeliveryDocketOrderItemService } from './_shared/services/dto-delivery-docket-order-item.service';
import { dtoAppUserColumnService } from './_shared/services/dto-app-user-column.service';
import { dtoTPPoleInspectionItemSummaryService } from './_shared/services/dto-tppole-inspection-item-summary.service';
import { LoadingDocketDeliveryService } from './_shared/services/loading-docket-delivery.service';
import { dtoLoadingDocketService } from './_shared/services/dto-loading-docket.service';
import { dtoLoadingDocketDeliveryService } from './_shared/services/dto-loading-docket-delivery.service';
import { RoyaltyRateService } from './_shared/services/royalty-rate.service';
import { dtoRoyaltyRateService } from './_shared/services/dto-royalty-rate.service';
import { dtoDeliveryDocketItemService } from './_shared/services/dto-delivery-docket-item.service';
import { dtoOrdersDeliveryRateService } from './_shared/services/dto-orders-delivery-rate.service';
import { OrdersDeliveryRateService } from './_shared/services/orders-delivery-rate.service';
import { dtoLoadingDocketItemService } from './_shared/services/dto-loading-docket-item.service';
import { LoadingLocationService } from './_shared/services/loading-location.service';
import { DeliveryRateService } from './_shared/services/delivery-rate.service';
import { dtoOrderItemService } from './_shared/services/dto-order-item.service';
import { dtoCustomerService } from './_shared/services/dto-customer.service';
import { dtoCustomerDeliveryAddressService } from './_shared/services/dto-customer-delivery-address.service';
import { ClientDeliveryAddressService } from './_shared/services/client-delivery-address.service';
import { PrinterService } from './_shared/services/printer.service';
import { dtoAppPrinterService } from './_shared/services/dto-app-printer.service';
import { dtoOrdersService } from './_shared/services/dto-orders.service';
import { PrintService } from './_shared/services/app-services/print.service';
import { MachineRunService } from './_shared/services/machine-run.service';
import { dtoMachineRunService } from './_shared/services/dto-machine-run.service';
import { BoardTallyRecordOrderItemService } from './_shared/services/board-tally-record-order-item.service';
import { MobilePlantEquipmentService } from './_shared/services/mobile-plant-equipment.service';
import { MobilePlantCategoryService } from './_shared/services/mobile-plant-category.service';
import { dtoHaulingCompanyService } from './_shared/services/dto-hauling-company.service';
import { dtoHaulingCompanyDriverService } from './_shared/services/dto-hauling-company-driver.service';
import { HaulingCompanyDriverService } from './_shared/services/hauling-company-driver.service';
import { DiscTemplateConfigService } from './_shared/services/disc-template-config.service';
import { dtoDiscTemplateConfigService } from './_shared/services/dto-disc-template-config.service';
import { dtoOrdersCustomerDeliveryContactService } from './_shared/services/dto-orders-customer-delivery-contact.service';
import { dtoDeliveryDocketCustomerDeliveryContactService } from './_shared/services/dto-delivery-docket-customer-delivery-contact.service';
import { DeliveryDocketCustomerDeliveryContactService } from './_shared/services/delivery-docket-customer-delivery-contact.service';
import { OrdersCustomerDeliveryContactService } from './_shared/services/orders-customer-delivery-contact.service';
import { AddressService } from './_shared/services/address';
import { dtoCustomerContactService } from './_shared/services/dto-customer-contact.service';
import { FileService } from './_shared/services/app-services/file.service';
import { dtoBoardTallyRecordHistoryService } from './_shared/services/dto-board-tally-record-history.service';
import { AppPreferenceService } from './_shared/services/app-preference.service';
import { AppUserPreferenceService } from './_shared/services/app-user-preference.service';
import { SQLParamService } from './_shared/services/app-services/sql-param.service';
import { dtoProductTypeService } from './_shared/services/dto-product-type.service';
import { dtoSpeciesService } from './_shared/services/dto-species.service';
import { dtoGradeService } from './_shared/services/dto-grade.service';
import { dtoProductProfileService } from './_shared/services/dto-product-profile.service';
import { dtoProductCodeService } from './_shared/services/dto-product-code.service';
import { SettingsService } from './_shared/services/settings.service';
import { ReckonSettingsService } from './_shared/services/reckon.service';
import { AppUserInfoService } from './_shared/services/app-user-info.service';
import { dtoCodeBuilderColumnService } from './_shared/services/dto-code-builder-column.service';
import { dtoTPChargeItemService } from './_shared/services/dto-tpcharge-item.service';
import { dtoTPChargeService } from './_shared/services/dto-tpcharge.service';
import { CHHPoleStockService } from './_shared/services/chhpole-stock.service';
import { dtoTaxInvoiceDailyTotalsService } from './_shared/business-objects/dto-tax-invoice-daily-totals.service';
import { SaleLocationService } from './_shared/services/sale-location.service';
import { dtoTaxInvoiceWeeklyTotalsService } from './_shared/services/dto-tax-invoice-weekly-totals.service';
import { dtoTaxInvoiceMonthlyTotalsService } from './_shared/services/dto-tax-invoice-monthly-totals.service';
import { dtoTaxInvoiceProductSummaryService } from './_shared/services/dto-tax-invoice-product-summary.service';
import { dtoDeliveredSalesBySectionService } from './_shared/services/dto-delivered-sales-by-section.service';
import { PickupLocationService } from './_shared/services/pickup-location.service';
import { dtoPickupLocationService } from './_shared/services/dto-pickup-location.service';
import { UtilityService } from './_shared/services/app-services/utility.service';
import { dtoDeliveryDocketOrderStockItemService } from './_shared/services/dto-delivery-docket-order-stock-item.service';
import { dtoAppUserPrinterService } from './_shared/services/dto-app-user-printer.service';
import { dtoAppUserPrinterDefaultService } from './_shared/services/dto-app-user-printer-default.service';
import { AppUserPrinterDefaultService } from './_shared/services/app-user-printer-default.service';
import { dtoPrinterService } from './_shared/services/dto-printer.service';
import { PrinterLocationService } from './_shared/services/printer-location.service';
import { dtoPrinterLocationService } from './_shared/services/dto-printer-location.service';
import { PoleInspectionItemIdentifierService } from './_shared/services/pole-inspection-item-identifier.service';
import { dtoPoleInspectionItemService } from './_shared/services/dto-pole-inspection-item.service';
import { PoleInspectionItemService } from './_shared/services/pole-inspection-item.service';
import { dtoPoleInspectionItemIdentifierService } from './_shared/services/dto-pole-inspection-item-identifier.service';
import { GuestService } from './_shared/services/guest.service';
import { dtoWebPublishStockSummaryService } from './_shared/services/dto-web-publish-stock-summary.service';
import { dtoStockItemQuantitySummaryService } from './_shared/services/dto-stock-item-quantity-summary.service';
import { BoardTallyRecordPhotoService } from './_shared/services/board-tally-record-photo.service';
import { dtoProductTypeCategoryGroupedService } from './_shared/services/dto-product-type-category-grouped.service';



@NgModule({
  imports: [
  //  ProductCodeService, ProductTypeCategoryService, ProductTypeService, ProductProfileService, SpeciesService, BoardGradeService, BoardTallyRecordService, OrderService,
  //  CustomerService, CustomerDeliveryAddressService
  ],
  //declarations: [
  //  MatRipple
  //  ],
  providers: [
    ProductCodeService, ProductTypeCategoryService, ProductTypeService, ProductProfileService, SpeciesService, BoardGradeService, BoardTallyRecordService, OrderService,
    CustomerService, CustomerDeliveryAddressService, CustomerContactService, OrderItemService, SharedService, DeliveryDocketService, DeliveryDocketOrderItemService,
    DeliveryDocketItemService, StockContainerService, StockContainerTypeService, PricingCategoryService, NavigationService, DurabilityClassService,
    OrderItemProcessService, OrderItemProcessOrderItemService, TaxInvoiceService, TaxInvoiceItemService, AppPrinterService, PriceChangeReasonService,
    BPStocktakeService, PoleService, PoleTrimmingItemService, IOService,
    TrimingItemReasonForChangeService, PoleTrimingItemReasonForChangeService, GeoStockLocationService, AppUserGeoStockLocationService, 
    AlertService, AppUserService, TableService, ColumnService, dtoDeliveredSalesBySectionDetailAllService, dtoBoardTallyRecordNotInStockService,
    dtoDeliveryDocketService, DeliveryDocketStockTransferService, FormService, MoulderService, BoardTallyRecordMachineRunService, BoardTallyRecordMoulderService,
    BoardTallyRecordProductProfileService, dtoStockItemDisplayService, dtoStockItemService, dtoTPPoleInspectionService, TPPoleInspectionService,
    EmployeeService, dtoTPPoleInspectionItemService, BoardTallyRecordDeliveryTransferredService, EmailService, AppService, AppPageService,
    ClientService, OrdersService, TPChargeItemService, ElectricalAuthoritySpecService, TPPoleInspectionItemService, AppUserColumnService, AppPageColumnService,
    dtoAppPageColumnService, dtoAppUserColumnSetService, AppUserColumnSetService, PoleCubeService, PositionService, UserPositionService,
    dtoTPPoleInspectionLoadingDocketService, LoadingDocketService, HaulingCompanyService, dtoDeliveryDocketOrderItemService, dtoAppUserColumnService,
    dtoTPPoleInspectionItemSummaryService, LoadingDocketDeliveryService, dtoLoadingDocketService, dtoLoadingDocketDeliveryService, RoyaltyRateService,
    dtoRoyaltyRateService, dtoDeliveryDocketItemService, dtoOrdersDeliveryRateService, OrdersDeliveryRateService, dtoLoadingDocketItemService, LoadingLocationService,
    DeliveryRateService, dtoOrderItemService, dtoCustomerService, dtoCustomerDeliveryAddressService, ClientDeliveryAddressService, PrinterService, dtoAppPrinterService, AppPrinterService,
    dtoOrdersService, PrintService, MachineRunService, dtoMachineRunService, BoardTallyRecordOrderItemService, MobilePlantEquipmentService, MobilePlantCategoryService,
    dtoHaulingCompanyService, dtoHaulingCompanyDriverService, HaulingCompanyDriverService, DiscTemplateConfigService, dtoDiscTemplateConfigService,
    dtoDeliveryDocketCustomerDeliveryContactService, dtoOrdersCustomerDeliveryContactService, DeliveryDocketCustomerDeliveryContactService, OrdersCustomerDeliveryContactService,
    AddressService, dtoCustomerContactService, FileService, dtoBoardTallyRecordHistoryService, AppPreferenceService, AppUserPreferenceService,
    SQLParamService, dtoProductTypeService, dtoSpeciesService, dtoGradeService, dtoProductProfileService, dtoProductCodeService, SettingsService,
    ReckonSettingsService, AppUserInfoService, dtoCodeBuilderColumnService, dtoTPChargeItemService, dtoTPChargeService, CHHPoleStockService, dtoTaxInvoiceDailyTotalsService,
    SaleLocationService, dtoTaxInvoiceWeeklyTotalsService, dtoTaxInvoiceMonthlyTotalsService, dtoTaxInvoiceProductSummaryService, dtoDeliveredSalesBySectionService,
    PickupLocationService, dtoPickupLocationService, UtilityService, dtoDeliveryDocketOrderStockItemService, dtoAppUserPrinterService, dtoAppUserPrinterDefaultService,
    AppUserPrinterDefaultService, dtoPrinterService, PrinterLocationService, dtoPrinterLocationService, PoleInspectionItemService, PoleInspectionItemIdentifierService, dtoPoleInspectionItemService, 
    dtoPoleInspectionItemIdentifierService, GuestService, dtoWebPublishStockSummaryService, dtoStockItemQuantitySummaryService, BoardTallyRecordPhotoService,
    dtoProductTypeCategoryGroupedService
  ],
  exports: [
  //  ProductCodeService, ProductTypeCategoryService, ProductTypeService, ProductProfileService, SpeciesService, BoardGradeService, BoardTallyRecordService, OrderService,
  //  CustomerService, CustomerDeliveryAddressService
  ],

})

export class CHHServiceModule {}
