import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileAttachment } from '../../_shared/app-objects/file-attachment';
import { BoardTallyRecordPhoto } from '../../_shared/business-objects/board-tally-record-photo.bo';
import { dtoStockItemDisplay } from '../../_shared/business-objects/dto-stock-item-display.bo';

@Component({
  selector: 'app-photo-viewer-dialog',
  templateUrl: './photo-viewer-dialog.component.html',
  styleUrl: './photo-viewer-dialog.component.scss'
})
export class PhotoViewerDialogComponent {

  @Output() updatePhotosEvent = new EventEmitter<FileAttachment[]>();

  path: string;
  folderName: string;
  fileNameOverride: string;
  boardTallyRecordPhotos: BoardTallyRecordPhoto[];

  constructor(private dialogRef: MatDialogRef<PhotoViewerDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: { path: string, folderName: string, fileNameOverride: string, boardTallyRecordPhotos: BoardTallyRecordPhoto[] }) {

    this.path = data.path;
    this.folderName = data.folderName;
    this.fileNameOverride = data.fileNameOverride;
    this.boardTallyRecordPhotos = data.boardTallyRecordPhotos;

    console.log("fileNameOverride: ", data.fileNameOverride);

  }

  emailPhotos(fileAttachments: FileAttachment[]) {

    this.dialogRef.close({ status: "emailphotos", fileAttachments: fileAttachments });

  }

  photoViewerClose(fileAttachments: FileAttachment[]) {

    this.dialogRef.close({ status: "cancel", fileAttachments: fileAttachments });

  }

  deletedAllPhotos(e) {

    console.log("deletedAllPhotos - in dialog");

    this.dialogRef.close({ status: "deletedallphotos", fileAttachments: null });

  }

  updatePhotos(fileAttachments: FileAttachment[]) {
    this.updatePhotosEvent.emit(fileAttachments);
  }


  deletedPhotos(fileAttachments: FileAttachment[]) {

    console.log("deletedPhotos - in dialog");

    this.dialogRef.close({ status: "deletedphotos", fileAttachments: fileAttachments });

  }

}
