import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Pole } from '../business-objects/pole.bo';
import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { AlertService } from './alert.service';
//import { LiteralMapEntry } from '@angular/compiler/src/output/output_ast';
import { SQLParamArray } from '../business-objects/sql-param-array';

@Injectable()
export class PoleService {

  public _baseUrl: string;

  public nsw_kNRange = "(^[2]$|^[4]$|^[6]$|^[8]$)|(^[1][1]$|^[1][2]$|^[1][4]$|^[1][5]$|^[1][6]$|^[1][8]$|^[2][0]$|^[2][1]$|^[2][5]$|^[3][0]$|^[3][5]$|^[4][5]$|^[3][5][0]$|^[4][0][0]$|^[4][2][5]$|^[4][5][0]$|^[4][7][5]$|^[5][0][0]$|^[5][2][5]$)";
  public qv_kNRange = "(^[5]$|^[8]$)|(^[1][2]$|^[1][6]$|^[1][8]$|^[2][0]$)";

  public nsw_LengthRange = "((^[6]$|^[7].[5]$|^[8]$|^[9]$|^[9].[5]$|^[1][0]$|^[1][0].[5]$|^[1][1]$|^[1][2].[5]$|^[1][4]$|^[1][5].[5]$|^[1][7]$|^[1][8].[5]$|^[2][0]$|^[2][1].[5]$|^[2][3]$|^[2][4].[5]$|^[2][6]$))";
  public qv_LengthRange = "((^[9].[5]$|^[1][1]$|^[1][2].[5]$|^[1][4]$|^[1][5].[5]$|^[1][7]$|^[1][8].[5]$|^[2][0]$|^[2][1].[5]$|^[2][3]$))";

  public original_nsw_LengthValidator: ValidatorFn = Validators.pattern(this.nsw_LengthRange);
  public finished_nsw_LengthValidator: ValidatorFn = Validators.pattern(this.nsw_LengthRange);

  public original_qv_LengthValidator: ValidatorFn = Validators.pattern(this.nsw_LengthRange);
  public finished_qv_LengthValidator: ValidatorFn = Validators.pattern(this.nsw_LengthRange);

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
    this._baseUrl = baseUrl;
  }

  getPoleByRowguid(gPoleId: string) {
    return this.http.get<Pole>(this._baseUrl + 'api/pole/GetPoleByRowguid/' + gPoleId);
  }

  getPoleParamArray(aParam: SQLParamArray[][]) {
    return this.http.post<Pole[]>(this._baseUrl + 'api/Pole/GetPolesParamArray/', aParam);
  }

  getPole(length: number, kn: number, qv: boolean, sub: boolean, plus: boolean, regal: boolean) {
    return this.http.get<Pole>(this._baseUrl + 'api/pole/GetPole/' + length + '/' + kn + '/' + qv + '/' + sub + '/' + plus + '/' + regal);
  }

  GetPoleInStock(length: number, kn: number, qv: boolean, sub: boolean, plus: boolean, regal: boolean) {
    return this.http.get<Pole>(this._baseUrl + 'api/pole/GetPoleInStock/' + length + '/' + kn + '/' + qv + '/' + sub + '/' + plus + '/' + regal);
  }

  getPoleList() {
    return this.http.get(this._baseUrl + 'api/pole/GetList/');
  }


  getPolePromise(length: number, kn: number, qv: boolean, sub: boolean, plus: boolean, regal: boolean) {
    return new Promise<Pole>(resolve => {
      this.getPole(length, kn, qv, sub, plus, regal).subscribe(pole => {
        resolve(pole);
      }, (err: HttpErrorResponse) => {
        this.alertService.openSnackBarError("There was a problem getting the pole " + length.toString() + " / " + kn.toString() + (qv == true ? "QV" : ""), "Close", "center", "bottom", 3000, true, err.message);
        resolve(null);
      });
    });
  }

  getPoleByIdPromise(poleId: string) {
    return new Promise<Pole>(resolve => {
      this.getPoleByRowguid(poleId).subscribe(pole => {
        resolve(pole);
      }, (err: HttpErrorResponse) => {
        this.alertService.openSnackBarError("There was a problem getting the pole ", "Close", "center", "bottom", 3000, true, err.message);
        resolve(null);
      });
    });
  } 

  createPole(pole: Pole) {
    return this.http.post<Pole>(this._baseUrl + 'api/Pole/CreatePole/', pole);
  }

  updatePole(pole: Pole) {
    return this.http.put<Pole>(this._baseUrl + 'api/Pole/UpdatePole/', pole);
  }

  async getPoleParamArrayPromise(aParam: SQLParamArray[][]) {
    return new Promise<Pole[]>(resolve => {
      this.getPoleParamArray(aParam).subscribe(poles => {
        resolve(poles);
      }, err => {
        this.alertService.openSnackBarError("Error getting Pole records", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }

  async updatePolePromise(pole: Pole) {
    return new Promise<Pole>(resolve => {
      this.updatePole(pole).subscribe(pole => {
        resolve(pole);
      }, (err: HttpErrorResponse) => {
        this.alertService.openSnackBarError("Error updating Pole record", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }

  async createPolePromise(pole: Pole) {
    return new Promise<Pole>(resolve => {
      this.createPole(pole).subscribe(pole => {
        resolve(pole);
      }, (err: HttpErrorResponse) => {
        this.alertService.openSnackBarError("Error creating Pole record", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }


  public validateDiameter(diameterControl: AbstractControl, qv: boolean) {
    let measureDef: string = 'Diameter'

    if (qv) {
      measureDef = 'Circumference';
    }

    if (diameterControl.hasError('required')) {
      return 'You must enter a value';
    }

    if (diameterControl.hasError('min')) {
      return 'The ' + measureDef.toLowerCase() + ' is too small';
    }

    if (diameterControl.hasError('max')) {
      return 'The ' + measureDef.toLowerCase() + ' is too big';
    }

    return diameterControl.hasError('maxlength') ? measureDef + ' is too big.' : '';

  }

  public validateKN(knControl: AbstractControl) {
    if (knControl.hasError('pattern')) {
      return 'The KN is not valid';
    }
  }

  public validateLength(lengthControl: AbstractControl) {
    if (lengthControl.hasError('min')) {
      return 'The length is too short';
    }

    if (lengthControl.hasError('max')) {
      return 'The length is too long';
    }

    if (lengthControl.hasError('pattern')) {
      return 'The Length is not valid for a Pole';
    }
  }
  
}  
