import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SQLParamArray } from '../business-objects/sql-param-array';
import { AlertService } from './alert.service';
import { BoardTallyRecordPhoto } from '../business-objects/board-tally-record-photo.bo';

@Injectable()
export class BoardTallyRecordPhotoService
{

	public _baseUrl: string;

	constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private alertService: AlertService) {
		this._baseUrl = baseUrl;
	}

	getBoardTallyRecordPhoto(boardTallyRecordPhotoId: string) {
		return this.http.get<BoardTallyRecordPhoto>(this._baseUrl + 'api/BoardTallyRecordPhoto/' + boardTallyRecordPhotoId);
	}

  getBoardTallyRecordPhotos(boardTallyRecordId: string) {
    return this.http.get<BoardTallyRecordPhoto[]>(this._baseUrl + 'api/BoardTallyRecordPhoto/GetBoardTallyRecordPhotos/' + boardTallyRecordId);
  }

	getBoardTallyRecordPhotoParamArray(aParam: SQLParamArray[][]) {
		return this.http.post<BoardTallyRecordPhoto[]>(this._baseUrl + 'api/BoardTallyRecordPhoto/GetBoardTallyRecordPhotosParamArray/', aParam);
	}

	getAllBoardTallyRecordPhotos() {
		return this.http.get<BoardTallyRecordPhoto[]>(this._baseUrl + 'api/BoardTallyRecordPhoto/GetAllBoardTallyRecordPhotos/');
	}

	//getAlldtoBoardTallyRecordPhotos() {
	//	return this.http.get<dtoBoardTallyRecordPhoto[]>(this._baseUrl + 'api/BoardTallyRecordPhoto/GetAllBoardTallyRecordPhotos/');
	//}

	createBoardTallyRecordPhoto(boardTallyRecordPhoto: BoardTallyRecordPhoto) {
		return this.http.post<BoardTallyRecordPhoto>(this._baseUrl + 'api/BoardTallyRecordPhoto/CreateBoardTallyRecordPhoto/', boardTallyRecordPhoto);
	}

	updateBoardTallyRecordPhoto(boardTallyRecordPhoto: BoardTallyRecordPhoto) {
		return this.http.put<BoardTallyRecordPhoto>(this._baseUrl + 'api/BoardTallyRecordPhoto/UpdateBoardTallyRecordPhoto/', boardTallyRecordPhoto);
	}

	deleteBoardTallyRecordPhoto(boardTallyRecordPhotoId: string) {
		return this.http.delete(this._baseUrl + 'api/BoardTallyRecordPhoto/DeleteBoardTallyRecordPhoto/' + boardTallyRecordPhotoId);
	}

  async getBoardTallyRecordPhotosPromise(boardTallyRecordId: string) {
    return new Promise<BoardTallyRecordPhoto[]>(resolve => {
      this.getBoardTallyRecordPhotos(boardTallyRecordId).subscribe(boardTallyRecordPhoto => {
        resolve(boardTallyRecordPhoto);
      }, err => {
        this.alertService.openSnackBarError("Error getting BoardTallyRecordPhoto records", "Close", "center", "bottom", 4000, true, err.message);
        resolve(null);
      });
    });
  }

	async getBoardTallyRecordPhotoPromise(boardTallyRecordPhotoId: string) {
		return new Promise<BoardTallyRecordPhoto>(resolve => {
			this.getBoardTallyRecordPhoto(boardTallyRecordPhotoId).subscribe(boardTallyRecordPhoto => {
				resolve(boardTallyRecordPhoto);
			}, err => {
				this.alertService.openSnackBarError("Error getting BoardTallyRecordPhoto records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async getBoardTallyRecordPhotoParamArrayPromise(aParam: SQLParamArray[][]) {
		return new Promise<BoardTallyRecordPhoto[]>(resolve => {
			this.getBoardTallyRecordPhotoParamArray(aParam).subscribe(boardTallyRecordPhotos => {
				resolve(boardTallyRecordPhotos);
			}, err => {
				this.alertService.openSnackBarError("Error getting BoardTallyRecordPhoto records", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async updateBoardTallyRecordPhotoPromise(boardTallyRecordPhoto: BoardTallyRecordPhoto) {
		return new Promise<BoardTallyRecordPhoto>(resolve => {
			this.updateBoardTallyRecordPhoto(boardTallyRecordPhoto).subscribe(boardTallyRecordPhoto => {
				resolve(boardTallyRecordPhoto);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error updating BoardTallyRecordPhoto record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async createBoardTallyRecordPhotoPromise(boardTallyRecordPhoto: BoardTallyRecordPhoto) {
		return new Promise<BoardTallyRecordPhoto>(resolve => {
			this.createBoardTallyRecordPhoto(boardTallyRecordPhoto).subscribe(boardTallyRecordPhoto => {
				resolve(boardTallyRecordPhoto);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error creating BoardTallyRecordPhoto record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(null);
			});
		});
	}

	async deleteBoardTallyRecordPhotoPromise(boardTallyRecordPhotoId: string) {
		return new Promise<boolean>(resolve => {
			this.deleteBoardTallyRecordPhoto(boardTallyRecordPhotoId).subscribe(boardTallyRecordPhoto => {
				resolve(true);
			}, (err: HttpErrorResponse) => {
				this.alertService.openSnackBarError("Error removing BoardTallyRecordPhoto record", "Close", "center", "bottom", 4000, true, err.message);
				resolve(false);
			});
		});
	}

}
